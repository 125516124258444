import api_config from '../config/api'

export default {
	getContact() {
		return api_config.get('api/contact/1')
	},
	async updateContact({ id, form_data }, token) {
		return api_config.post(`api/contact/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
	},
}
