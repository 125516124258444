import api_config from '../config/api'

export default {
	async addCareer(form_data, token) {
		return api_config.post('api/career', form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
	},
	async deleteCareer(banner_id, token) {
		return api_config.delete(`api/career/${banner_id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	getCareers(lang) {
		return api_config.get(`api/career/${lang}`)
	},
	async updateCareer({ id, form_data }, token) {
		return api_config.post(`api/career/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
	},
}
