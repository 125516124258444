import user_api from '../../../apis/user_management'

export default {
	async addUser({ rootGetters }, form_data) {
		return await user_api
			.addUser(form_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
	async deleteUser({ rootGetters }, id) {
		await user_api.deleteUser(id, rootGetters['AUTH/auth_token'])
	},
	async getUsers({ commit, rootGetters }) {
		const users = await user_api.getUsers(rootGetters['AUTH/auth_token'])

		if (users.data.data.length > 0) {
			users.data.data = users.data.data.map(user => ({
				...user,
				role: user.role_id === 1 ? 'Superadmin' : 'Admin',
			}))
		}

		commit('setUsers', users.data.data)
	},
	async updateUser({ rootGetters }, update_data) {
		return await user_api
			.updateUser(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
