<template>
	<div class="modal-mask" v-on:click="$emit('close')">
		<div class="modal-wrapper">
			<div
				class="modal-container"
				:class="options.container_class"
				@click.stop=""
			>
				<div
					class="modal-body"
					v-if="options.inputs"
					:class="{
						'multi-column': options.multi_column,
						triple: options.triple_column,
					}"
				>
					<template v-if="options.multi_column">
						<template v-if="!options.multi_image">
							<div class="image-wrapper col">
								<p class="title modal" v-if="options.title">
									{{ getTitle(options.title) }}
								</p>
								<p
									class="subtitle modal"
									v-if="options.first_title"
								>
									{{ options.first_title }}
								</p>
								<div class="image-preview">
									<img
										:src="
											getImage(
												options.file_inputs[0].name,
											)
										"
										v-if="
											getImage(
												options.file_inputs[0].name,
											)
										"
									/>
									<div class="placeholder" v-else>
										<i class="fa fa-image"></i>
									</div>
								</div>
								<div class="form-wrapper image">
									<div class="text-col">
										<p class="title">Unggah gambar</p>
										<p class="description">
											Format gambar .jpg .jpeg .png
										</p>
									</div>
									<input
										accept=".jpg, .png, .jpeg"
										class="hidden"
										:ref="options.file_inputs[0].name"
										:name="options.file_inputs[0].name"
										type="file"
										@change="handleFileInput"
									/>
									<button
										class="btn-upload"
										@click="
											$refs[
												options.file_inputs[0].name
											].click()
										"
									>
										<i class="fa fa-camera"></i>Pilih gambar
									</button>
									<p
										class="error-message"
										v-if="
											input_errors[
												options.file_inputs[0].name
											]
										"
									>
										{{
											input_errors[
												options.file_inputs[0].name
											]
										}}
									</p>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="image-wrapper col">
								<p class="title modal" v-if="options.title">
									{{ getTitle(options.title) }}
								</p>
								<p
									class="subtitle modal"
									v-if="options.first_title"
								>
									{{ options.first_title }}
								</p>
								<div class="images-container">
									<div
										class="image-container"
										v-bind:key="input"
										v-for="input in options.file_inputs"
									>
										<div class="image-preview">
											<div
												v-if="getImage(input.name)"
												class="img-wrapper"
											>
												<img
													:src="getImage(input.name)"
												/>
												<div
													class="overlay-delete"
													@click="
														handleDeleteImage(
															input.name,
														)
													"
												>
													<i class="fa fa-trash"></i>
												</div>
											</div>
											<div
												class="placeholder"
												@click="
													$refs[input.name].click()
												"
												v-else
											>
												<i class="fa fa-plus"></i>
											</div>
										</div>
										<p class="input-label">
											{{ input.title }}
										</p>
										<div class="form-wrapper image">
											<input
												accept=".jpg, .png, .jpeg"
												class="hidden"
												:ref="input.name"
												:name="input.name"
												type="file"
												@change="handleFileInput"
											/>
											<p
												class="error-message"
												v-if="input_errors[input.name]"
											>
												{{ input_errors[input.name] }}
											</p>
										</div>
									</div>
								</div>
								<div class="form-wrapper text">
									<div class="text-col">
										<p class="title">Unggah foto</p>
									</div>
									<p class="notice">
										<i class="fa fa-circle"></i>Format
										gambar .jpg .jpeg .png
									</p>
								</div>
							</div>
						</template>
						<div
							class="text-wrapper col"
							v-if="!isEmpty(getMiddleColInputs())"
						>
							<p
								class="subtitle modal"
								v-if="options.middle_title"
							>
								{{ options.middle_title }}
							</p>
							<form ref="form-input">
								<div
									class="form-wrapper text"
									v-bind:key="input"
									v-for="input in getMiddleColInputs()"
								>
									<div class="text-col">
										<p class="title">
											{{ input.title }}
										</p>
									</div>
									<input
										:type="input.type"
										:name="input.name"
										v-model="input_values[input.name]"
										step="0.01"
										v-if="
											input.type === 'text' ||
											input.type === 'password' ||
											input.type === 'number'
										"
									/>
									<input
										:type="input.type"
										:name="input.name"
										v-model="input_values[input.name]"
										v-else-if="input.type === 'hidden'"
									/>
									<div
										class="radio-input"
										v-else-if="input.type === 'radio'"
									>
										<div
											class="input-col"
											v-bind:key="radio_val"
											v-for="radio_val in input.radio_values"
										>
											<input
												class="radio"
												type="radio"
												:value="radio_val.value"
												:name="input.name"
												v-model="
													input_values[input.name]
												"
												:checked="
													input_values[input.name] ===
													radio_val.value
												"
											/>
											<p class="name">
												{{ radio_val.name }}
											</p>
										</div>
									</div>
									<textarea
										rows="5"
										:name="input.name"
										v-model="input_values[input.name]"
										v-else-if="input.type === 'textarea'"
									/>
									<multiselect
										v-model="input_values[input.name]"
										:mode="
											input.type === 'multiselect'
												? 'tags'
												: 'single'
										"
										:closeOnSelect="false"
										:searchable="true"
										:trackBy="input.option_config.label"
										:label="input.option_config.label"
										:valueProp="input.option_config.value"
										:options="
											getSelectOptions(input.options)
										"
										:name="input.name"
										v-else-if="
											(input.type === 'multiselect' ||
												input.type ===
													'singleselect') &&
											!isEmpty(input.options)
										"
									/>
									<QuillEditor
										:name="input.name"
										contentType="html"
										theme="snow"
										toolbar="full"
										v-model:content="
											input_values[input.name]
										"
										v-else
									/>
									<p
										class="error-message"
										v-if="input_errors[input.name]"
									>
										{{ input_errors[input.name] }}
									</p>
								</div>
							</form>
							<div class="form-wrapper text">
								<div class="text-col">
									<p class="title">video</p>
								</div>
								<p class="notice">
									<i class="fa fa-circle"></i>Masukkan id
									video youtube
								</p>
							</div>
						</div>
						<div class="text-wrapper col">
							<p class="title modal" v-if="options.title">
								{{ getTitle(options.title) }}
							</p>
							<form ref="form-input">
								<div
									class="form-wrapper text"
									v-bind:key="input"
									v-for="input in getLastColInputs()"
								>
									<div class="text-col">
										<p class="title">
											{{ input.title }}
										</p>
									</div>
									<input
										:type="input.type"
										:name="input.name"
										step="0.01"
										v-model="input_values[input.name]"
										v-if="
											input.type === 'text' ||
											input.type === 'password' ||
											input.type === 'number'
										"
									/>
									<input
										:type="input.type"
										:name="input.name"
										v-model="input_values[input.name]"
										v-else-if="input.type === 'hidden'"
									/>
									<div
										class="radio-input"
										v-else-if="input.type === 'radio'"
									>
										<div
											class="input-col"
											v-bind:key="radio_val"
											v-for="radio_val in input.radio_values"
										>
											<input
												class="radio"
												type="radio"
												:value="radio_val.value"
												:name="input.name"
												v-model="
													input_values[input.name]
												"
												:checked="
													input_values[input.name] ===
													radio_val.value
												"
											/>
											<p class="name">
												{{ radio_val.name }}
											</p>
										</div>
									</div>
									<textarea
										rows="5"
										:name="input.name"
										v-model="input_values[input.name]"
										v-else-if="input.type === 'textarea'"
									/>
									<multiselect
										v-model="input_values[input.name]"
										:mode="
											input.type === 'multiselect'
												? 'tags'
												: 'single'
										"
										:closeOnSelect="false"
										:searchable="true"
										:trackBy="input.option_config.label"
										:label="input.option_config.label"
										:valueProp="input.option_config.value"
										:options="
											getSelectOptions(input.options)
										"
										:name="input.name"
										v-else-if="
											(input.type === 'multiselect' ||
												input.type ===
													'singleselect') &&
											!isEmpty(input.options)
										"
									/>
									<QuillEditor
										:name="input.name"
										contentType="html"
										theme="snow"
										toolbar="full"
										v-model:content="
											input_values[input.name]
										"
										v-else
									/>
									<p
										class="error-message"
										v-if="input_errors[input.name]"
									>
										{{ input_errors[input.name] }}
									</p>
								</div>
							</form>
						</div>
					</template>
					<template v-else>
						<p class="title" v-if="options.title">
							{{ getTitle(options.title) }}
						</p>
						<form ref="form-input">
							<div
								class="form-wrapper text"
								v-bind:key="input"
								v-for="input in options.inputs"
							>
								<div class="text-col">
									<p class="title">
										{{ input.title }}
									</p>
								</div>
								<input
									:type="input.type"
									:name="input.name"
									step="0.01"
									v-model="input_values[input.name]"
									v-if="
										input.type === 'text' ||
										input.type === 'password' ||
										input.type === 'number'
									"
								/>
								<input
									:type="input.type"
									:name="input.name"
									v-model="input_values[input.name]"
									v-else-if="input.type === 'hidden'"
								/>
								<div
									class="radio-input"
									v-else-if="input.type === 'radio'"
								>
									<div
										class="input-col"
										v-bind:key="radio_val"
										v-for="radio_val in input.radio_values"
									>
										<input
											class="radio"
											type="radio"
											:value="radio_val.value"
											:name="input.name"
											v-model="input_values[input.name]"
											:checked="
												input_values[input.name] ===
												radio_val.value
											"
										/>
										<p class="name">{{ radio_val.name }}</p>
									</div>
								</div>
								<textarea
									rows="5"
									:name="input.name"
									v-model="input_values[input.name]"
									v-else-if="input.type === 'textarea'"
								/>
								<multiselect
									v-model="input_values[input.name]"
									:mode="
										input.type === 'multiselect'
											? 'tags'
											: 'single'
									"
									:closeOnSelect="false"
									:searchable="true"
									:trackBy="input.option_config.label"
									:label="input.option_config.label"
									:valueProp="input.option_config.value"
									:options="getSelectOptions(input.options)"
									:name="input.name"
									v-else-if="
										(input.type === 'multiselect' ||
											input.type === 'singleselect') &&
										!isEmpty(input.options)
									"
								/>
								<QuillEditor
									:name="input.name"
									contentType="html"
									theme="snow"
									toolbar="full"
									v-model:content="input_values[input.name]"
									v-else
								/>
								<p
									class="error-message"
									v-if="input_errors[input.name]"
								>
									{{ input_errors[input.name] }}
								</p>
							</div>
						</form>
					</template>
				</div>
				<div class="modal-footer mt-8 mb-4">
					<button class="btn-cancel" v-on:click="$emit('close')">
						Batal
					</button>
					<button
						class="btn-save"
						:class="{ disabled: !can_save || is_procecssing }"
						@click="handleSave()"
					>
						Simpan
						<i
							class="fas fa-circle-notch fa-spin ml-1"
							v-if="is_procecssing"
						></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import axios from 'axios'
import VueMultiselect from '@vueform/multiselect'
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'

export default {
	emits: ['close'],
	props: ['data', 'options'],
	components: {
		QuillEditor,
		multiselect: VueMultiselect,
	},
	data() {
		return {
			can_save: false,
			input_values: {},
			input_file_values: {},
			input_errors: {},
			is_procecssing: false,
		}
	},
	watch: {
		input_values: {
			handler: function (new_val) {
				new_val = { ...new_val, ...this.input_file_values }

				let inputs = this.options.inputs

				if (this.options.file_inputs) {
					inputs = [
						...this.options.inputs,
						...this.options.file_inputs,
					]
				}

				let empty_value = null

				if (Object.keys(this.input_file_values).length > 1) {
					empty_value = inputs.find(
						(input) =>
							new_val &&
							input.name.indexOf('image') < 0 &&
							input.name.indexOf('video') < 0 &&
							(new_val[input.name] === null ||
								new_val[input.name] === '' ||
								(typeof new_val[input.name] === 'object' &&
									new_val[input.name].length < 1)),
					)

					if (
						!new_val[this.options.file_inputs[0].name] &&
						!new_val['video_1']
					) {
						empty_value = true
					}
				} else {
					empty_value = inputs.find(
						(input) =>
							new_val &&
							(new_val[input.name] === null ||
								new_val[input.name] === '' ||
								(typeof new_val[input.name] === 'object' &&
									new_val[input.name].length < 1)),
					)
				}

				if (empty_value) {
					this.can_save = false
				} else {
					this.can_save = true
				}
			},
			deep: true,
		},
	},
	methods: {
		getImage(name) {
			return this.input_file_values[name] || this.data?.image
		},
		getLastColInputs() {
			return this.options.inputs.filter((input) => !input.col)
		},
		getMiddleColInputs() {
			return this.options.inputs.filter(
				(input) => input.col && input.col === 'middle',
			)
		},
		getSelectOptions(options) {
			if (typeof options === 'object') {
				return Object.values(options)
			} else {
				return options
			}
		},
		getTitle(title) {
			let action = 'Tambah'

			if (this.data && this.data.id) {
				action = 'Ubah'
			}

			return title.replace('$action', action)
		},
		handleDeleteImage(input_name) {
			this.$refs[input_name].value = null
			this.input_file_values[input_name] = null
		},
		handleFileInput(e) {
			const input_name = e.target.name
			if (e.target.files.length > 0) {
				this.input_file_values[input_name] = URL.createObjectURL(
					e.target.files[0],
				)

				if (Object.keys(this.input_file_values).length > 1) {
					const first_img =
						this.input_file_values[this.options.file_inputs[0].name]

					if (!first_img) {
						this.can_save = false
					} else {
						this.can_save = true
					}
				} else {
					this.can_save = true
				}
			} else {
				this.input_file_values[input_name] = null
				this.can_save = false
			}
		},
		async handleSave() {
			if (this.can_save && !this.is_procecssing) {
				this.input_errors = {}
				this.is_procecssing = true

				const form_data = new FormData()

				Object.keys(this.input_values).forEach((input) => {
					if (
						(this.data &&
							this.input_values[input] !== null &&
							this.input_values[input] !== '') ||
						!this.data
					) {
						if (typeof this.input_values[input] === 'object') {
							form_data.append(
								input,
								JSON.stringify(this.input_values[input]),
							)
						} else {
							if (
								input.indexOf('video') > -1 &&
								this.input_values[input]
							) {
								const get_num = input.replace('video_', '')
								form_data.append(
									`video[${Number(get_num) - 1}]`,
									this.input_values[input],
								)
							} else if (
								input.indexOf('image') < 0 &&
								input.indexOf('video') < 0
							) {
								form_data.append(
									input,
									this.input_values[input],
								)
							}
						}
					}
				})

				let count_file = 0

				const do_promise = await new Promise((resolve, reject) => {
					if (Object.keys(this.input_file_values).length > 0) {
						Object.keys(this.input_file_values).forEach(
							async (input, idx) => {
								const file_input = this.$refs[input]

								if (file_input.files[0]) {
									if (input.indexOf('_') > -1) {
										form_data.append(
											`image_temp[${idx}]`,
											file_input.files[0],
										)
										count_file++
									} else {
										form_data.append(
											input,
											file_input.files[0],
										)
										count_file++
									}
								} else if (
									this.data &&
									this.data.images &&
									this.data.images[idx] &&
									input.indexOf('_') > -1
								) {
									const image_file = await this.fetchImage(
										this.data.images[idx],
									)
									form_data.append(
										`image_temp[${idx}]`,
										image_file,
									)
									count_file++
								} else {
									count_file++
								}

								if (
									count_file ===
									Object.keys(this.input_file_values).length
								) {
									resolve()
								}
							},
						)
					} else {
						reject()
					}
				})
					.then(() => true)
					.catch(() => true)

				if (do_promise) {
					if (Object.keys(this.input_file_values).length > 1) {
						for (let i = 1; i <= 5; i++) {
							form_data.delete(`image_${i}`)
							form_data.delete(`video_${i}`)
						}

						for (let k = 0; k < 5; k++) {
							form_data.append(
								`image[${k}]`,
								form_data.get(`image_temp[${k}]`),
							)
							form_data.delete(`image_temp[${k}]`)
						}
					}

					let throw_data = form_data

					if (this.data && this.data.id) {
						throw_data = {
							id: this.data.id,
							form_data: form_data,
						}
					}

					this.$store
						.dispatch(
							`${this.options.store.prefix}/${
								this.data?.id
									? this.options.store.update
									: this.options.store.add
							}`,
							throw_data,
						)
						.then((data) => {
							if (data.success) {
								this.$store.dispatch(
									`${this.options.store.prefix}/${this.options.store.get}`,
								)
								this.$emit('close')
								this.$swal
									.fire({
										icon: 'success',
										title: `Berhasil ${
											this.data?.id
												? 'merubah'
												: 'menambah'
										} ${this.options.store.message}.`,
										showConfirmButton: false,
										timer: 1500,
									})
									.then(() => {
										this.is_procecssing = false
									})
							} else {
								this.is_procecssing = false
								if (data.data) {
									const err_keys = Object.keys(data.data)
									err_keys.forEach((err) => {
										let inputs = this.options.inputs
										if (
											this.options.file_inputs &&
											this.options.file_inputs.length > 0
										) {
											inputs = [
												...inputs,
												...this.options.file_inputs,
											]
										}
										const find_input_title = inputs.find(
											(input) => input.name === err,
										)
										this.input_errors[err] = data.data[
											err
										][0].replace(
											err,
											find_input_title.title,
										)
									})
								} else {
									this.$swal.fire({
										icon: 'info',
										title: data.msg,
										showConfirmButton: false,
										timer: 3000,
									})
								}
							}
						})
						.catch(() => {
							this.is_procecssing = false
							this.$swal.fire({
								icon: 'error',
								title: 'Terjadi kesalahan pada sistem, harap coba lagi.',
								showConfirmButton: false,
								timer: 2000,
							})
						})
				}
			}
		},
		async fetchImage(url) {
			return fetch(url, {
				method: 'GET',
				headers: { Accept: '*/*' },
			}).then((res) => res.blob())
		},
	},
	beforeMount() {
		this.options.inputs.forEach((input) => {
			if (input.type === 'radio') {
				this.input_values[input.name] = this.data
					? this.data[input.name]
					: input.radio_values[0].value
			} else if (input.type === 'hidden') {
				this.input_values[input.name] = input.value
			} else if (input.type === 'multiselect') {
				this.input_values[input.name] = this.data
					? this.data[input.name]
					: []
			} else {
				this.input_values[input.name] = this.data
					? this.data[input.name]
					: null
			}
		})

		if (this.options.file_inputs) {
			if (this.options.file_inputs.length < 2) {
				this.input_values[this.options.file_inputs[0].name] = this.data
					? this.data.image
					: null
				this.input_file_values[this.options.file_inputs[0].name] = this
					.data
					? this.data.image
					: null
			} else {
				this.options.file_inputs.forEach((input, idx) => {
					this.input_values[input.name] = this.data
						? this.data.images[idx]
						: null
					this.input_file_values[input.name] = this.data
						? this.data.images[idx]
						: null
				})
			}
		}
	},
}
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
@import '../../assets/scss/template/partial/_modal.scss';
</style>