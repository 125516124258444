import contact_api from '../../../apis/contact'

export default {
	async getContact({ commit }) {
		let contact = await contact_api.getContact()

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		if (contact.data.data) {
			contact = {
				...contact.data.data,
				image: `${api_uri}${contact.data.data.image}`,
			}
		}

		commit('setContact', contact)
	},
	async updateContact({ rootGetters }, update_data) {
		return await contact_api
			.updateContact(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
