import api_config from '../config/api'

export default {
	getWhyEdim(lang) {
		return api_config.get(`api/why_edim/${lang}`)
	},
	async updateWhyEdim({ id, form_data }, token) {
		return api_config.post(`api/why_edim/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
	},
}
