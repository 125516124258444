<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_category"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<button class="btn-add" @click="doAddCategory()">
			<i class="fa fa-plus"></i>Tambah
		</button>
		<div v-if="!isEmpty(state_categories)" class="mt-16">
			<my-table
				@init_data="init()"
				@edit="doEditCategory"
				:data="state_categories"
				:options="table_options"
				v-if="state_categories"
			></my-table>
		</div>
		<div v-else class="no-data"></div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../../components/partial/_Modal-text.vue'
	import table from '../../components/partial/_Table.vue'
	export default {
		data() {
			return {
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'small',
					inputs: [
						{
							name: 'name_ind',
							title: 'Nama indonesia',
							type: 'text',
						},
						{
							name: 'name_eng',
							title: 'Nama inggris',
							type: 'text',
						},
					],
					store: {
						add: 'addCategory',
						get: 'getCategories',
						message: 'kategori',
						prefix: 'CATEGORY',
						update: 'updateCategory',
					},
					title: '$action kategori',
				},
				table_options: {
					columns: [
						{
							name: 'No',
							value: 'increment',
						},
						{
							name: 'Nama indonesia',
							value: 'name_ind',
						},
						{
							name: 'Nama inggris',
							value: 'name_eng',
						},
						{
							name: 'Aksi',
							value: 'action',
						},
					],
					can_delete: true,
					can_edit: true,
					size: 'small',
					store: {
						delete: 'deleteCategory',
						edit: 'updateCategory',
						message: 'kategori',
						prefix: 'CATEGORY',
					},
				},
				selected_category: {
					id: null,
					name_eng: null,
					name_ind: null,
				},
			}
		},
		components: {
			'my-modal': modal,
			'my-table': table,
		},
		computed: {
			...mapGetters({
				state_categories: 'CATEGORY/categories',
			}),
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_category = {
					id: null,
					name_eng: null,
					name_ind: null,
				}
			},
			doAddCategory() {
				this.is_modal_hidden = false
			},
			doEditCategory(category) {
				this.selected_category = {
					id: category.id,
					name_eng: category.name_eng,
					name_ind: category.name_ind,
				}
				this.is_modal_hidden = false
			},
			init() {
				this.$store.dispatch('CATEGORY/getCategories')
				this.is_loading = false
			},
		},
		mounted() {
			this.init()
		},
	}
</script>
