<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_data"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<div class="action-tab md:flex">
			<button class="btn-add" @click="doAddSpecialist()">
				<i class="fa fa-plus"></i>Tambah
			</button>
			<div class="tab-container">
				<button
					class="tab"
					:class="{ active: selected_language === 'ind' }"
					@click="selected_language = 'ind'"
				>
					Indonesia
				</button>
				<button
					class="tab"
					:class="{ active: selected_language === 'eng' }"
					@click="selected_language = 'eng'"
				>
					Inggris
				</button>
			</div>
		</div>
		<div
			v-if="!isEmpty(state_specialists) && !isEmpty(state_projects)"
			class="item-list mt-16 specialist"
		>
			<div
				v-bind:key="specialist"
				v-for="specialist in state_specialists"
				class="item"
			>
				<img class="banner" :src="specialist.image" />
				<div class="text-wrapper">
					<div class="detail">
						<p class="specialist-title">
							{{ specialist[selected_language].title }}
						</p>
						<p class="project-count">
							{{ specialist.projects?.length }} Proyek
						</p>
					</div>
					<div class="action-col item-between">
						<button
							class="action edit"
							@click="doEditSpecialist(specialist)"
						>
							Edit
						</button>
						<button
							class="action delete"
							@click="doDeleteSpecialist(specialist.id)"
							v-if="deleting_list.indexOf(specialist.id) < 0"
						>
							<i class="fa fa-trash"></i>
						</button>
						<button class="action processing" v-else>
							<i class="fas fa-circle-notch fa-spin"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-data"></div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../../components/partial/_Modal-text.vue'
	export default {
		data() {
			return {
				deleting_list: [],
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'x-large',
					inputs: [
						{
							name: 'title_ind',
							title: 'Judul Indonesia',
							type: 'text',
						},
						{
							name: 'title_eng',
							title: 'Judul Inggris',
							type: 'text',
						},
						{
							name: 'description_ind',
							title: 'Deskripsi Indonesia',
							type: 'rte',
						},
						{
							name: 'description_eng',
							title: 'Deskripsi Inggris',
							type: 'rte',
						},
						{
							name: 'services_ind',
							title: 'Jasa Indonesia',
							type: 'rte',
						},
						{
							name: 'services_eng',
							title: 'Jasa Inggris',
							type: 'rte',
						},
						{
							name: 'projects_id',
							option_config: {
								label: 'project_name',
								value: 'id',
							},
							options: [],
							title: 'Proyek',
							type: 'multiselect',
						},
					],
					file_inputs: [
						{
							name: 'image',
							title: 'Gambar',
						},
					],
					multi_column: true,
					multi_image: false,
					store: {
						add: 'addSpecialist',
						get: 'getSpecialists',
						message: 'spesialis',
						prefix: 'SPECIALIST',
						update: 'updateSpecialist',
					},
					title: '$action spesialis',
				},
				selected_data: null,
				selected_language: 'ind',
			}
		},
		components: {
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_projects: 'PROJECT/projects',
				state_specialists: 'SPECIALIST/specialists',
			}),
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_data = null
			},
			doAddSpecialist() {
				if (this.state_projects.length < 1) {
					this.$swal.fire({
						icon: 'info',
						title:
							'Tidak ada proyek, silahkan tambahkan proyek dahulu.',
						showConfirmButton: false,
						timer: 3000,
					})
				} else {
					this.is_modal_hidden = false
				}
			},
			doDeleteSpecialist(id) {
				this.deleting_list.push(id)
				this.$store
					.dispatch('SPECIALIST/deleteSpecialist', id)
					.then(() => {
						this.$swal
							.fire({
								icon: 'success',
								title: 'Berhasil menghapus spesialis.',
								showConfirmButton: false,
								timer: 1500,
							})
							.then(() => {
								const index_item = this.deleting_list.indexOf(
									id,
								)
								delete this.deleting_list[index_item]
								this.init()
							})
					})
					.catch(() => {
						this.$swal.fire({
							icon: 'error',
							title:
								'Terjadi kesalahan pada sistem, harap coba lagi.',
							showConfirmButton: false,
							timer: 2000,
						})
					})
			},
			doEditSpecialist(data) {
				if (this.state_projects.length < 1) {
					this.$swal.fire({
						icon: 'info',
						title:
							'Tidak ada proyek, silahkan tambahkan proyek dahulu.',
						showConfirmButton: false,
						timer: 3000,
					})
				} else {
					data.projects = data.projects.map(
						project => project.project_id,
					)
					this.selected_data = {
						id: data.id,
						image: data.image,
						title_ind: data.ind.title,
						title_eng: data.eng.title,
						description_ind: data.ind.description,
						description_eng: data.eng.description,
						services_ind: data.ind.services,
						services_eng: data.eng.services,
						projects_id: data.projects,
					}
					this.is_modal_hidden = false
				}
			},
			init() {
				this.$store.dispatch('PROJECT/getProjects').then(() => {
					this.modal_options.inputs = this.modal_options.inputs.map(
						input => {
							if (input.name === 'projects_id') {
								input.options = this.state_projects
							}
							return input
						},
					)
				})
				this.$store.dispatch('SPECIALIST/getSpecialists')
				this.is_loading = false
			},
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/dashboard/specialist.scss';
</style>
