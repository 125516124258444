import api_config from '../config/api'

export default {
	async addProduct(form_data, token) {
		return api_config.post('api/product', form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	async deleteProduct(id, token) {
		return api_config.delete(`api/product/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	getProducts(language) {
		return api_config.get(`api/product/${language}/false`)
	},
	async updateProduct({ id, form_data }, token) {
		return api_config.post(`api/product/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
