import Cookies from 'js-cookie'

export default {
	setMe(state, me) {
		state.me = me
	},
	removeToken(state) {
		Cookies.remove('x-login')
		state.auth_token = null
		state.me = null
	},
	setToken(state, token) {
		Cookies.set('x-login', token.token, {
			expires: token.expires_in_minutes / 86400,
		})
		state.auth_token = token.token
	},
}
