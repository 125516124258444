import api_config from '../config/api'

export default {
	getMission(lang) {
		return api_config.get(`api/vision_and_mission/${lang}/2`)
	},
	getVision(lang) {
		return api_config.get(`api/vision_and_mission/${lang}/1`)
	},
	async updateVisionMission({ id, form_data }, token) {
		return api_config.post(`api/vision_and_mission/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
