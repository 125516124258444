import our_certificate_api from '../../../apis/certificate'

export default {
	async addCertificate({ rootGetters }, form_data) {
		await our_certificate_api.addCertificate(
			form_data,
			rootGetters['AUTH/auth_token'],
		)
	},
	async deleteCertificate({ rootGetters }, id) {
		await our_certificate_api.deleteCertificate(
			id,
			rootGetters['AUTH/auth_token'],
		)
	},
	async getOurCertificates({ commit }) {
		const our_specialists = await our_certificate_api.getCertificates()

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		const temp = {}

		if (our_specialists.data.data.length > 0) {
			our_specialists.data.data.forEach(item => {
				temp[item.id] = { ...item, image: `${api_uri}${item.image}` }
			})
		}

		commit('setOurCertificates', temp)
	},
	async updateCertificate({ rootGetters }, update_data) {
		await our_certificate_api.updateCertificate(
			update_data,
			rootGetters['AUTH/auth_token'],
		)
	},
}
