<template>
	<div class="login-wrapper">
		<div class="img-wrapper hidden md:block">
			<img class="w-full" :src="login_image" alt="login-img" />
		</div>
		<div class="login-form">
			<img :src="edim_logo" alt="edim-logo" />
			<p class="title">Login to dashboard</p>
			<p class="subtitle">Silahkan login untuk melanjutkan</p>
			<form @submit.prevent="doLogin">
				<div class="input-wrapper">
					<div class="input-group">
						<p class="name">Username</p>
						<input
							class="input"
							type="text"
							name="username"
							v-model="login_data.username"
						/>
					</div>
					<div class="input-group">
						<p class="name">Password</p>
						<input
							class="input"
							type="password"
							name="password"
							v-model="login_data.password"
						/>
					</div>
					<button type="submit" class="submit-form">Masuk</button>
				</div>
			</form>
		</div>
	</div>
</template>
<script>
import edim_logo from '../assets/img/edim-logo.png'
import login_image from '../assets/img/login-image.png'

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

export default {
	data() {
		return {
			edim_logo: edim_logo,
			login_data: {
				username: null,
				password: null,
			},
			login_image: login_image,
			router: null,
			store: null,
		}
	},
	methods: {
		doLogin() {
			this.store
				.dispatch('AUTH/doLogin', this.login_data)
				.then(() => {
					this.$swal
						.fire({
							icon: 'success',
							title: 'Berhasil masuk.',
							showConfirmButton: false,
							timer: 1500,
						})
						.then(() => {
							this.router.push({
								name: 'banner',
							})
						})
				})
				.catch(() => {
					this.$swal.fire({
						icon: 'error',
						title: 'Kredensial yang Kamu masukkan salah.',
						showConfirmButton: false,
						timer: 2000,
					})
				})
			this.login_data = {
				username: null,
				password: null,
			}
		},
	},
	mounted() {
		this.router = useRouter()
		this.store = useStore()
	},
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/login.scss';
</style>