import api_config from '../config/api'

export default {
	getOurTeam(lang) {
		return api_config.get(`api/our_team/${lang}`)
	},
	async updateOurTeam({ id, form_data }, token) {
		return api_config.post(`api/our_team/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
