import about_api from '../../../apis/about'

export default {
	async getAbout({ commit }) {
		const about_data_ind = await about_api.getAbout('ind')
		const about_data_eng = await about_api.getAbout('eng')

		let about_data = null
		if (about_data_ind.data.data) {
			about_data = {
				id: about_data_ind.data.data[0].id,
				eng: about_data_eng.data.data[0].description,
				ind: about_data_ind.data.data[0].description,
			}
		}

		commit('setAbout', about_data)
	},
	async updateAbout({ rootGetters }, update_data) {
		return await about_api
			.updateAbout(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
