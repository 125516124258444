import auth_api from '../../../apis/auth'

export default {
	async doLogin({ commit }, form_data) {
		const do_login = await auth_api.login(form_data)

		if (do_login.data) {
			commit('setToken', do_login.data.data)
		}
	},
	async doLogout({ commit, state }) {
		const do_logout = await auth_api.logout(state.auth_token)

		if (do_logout.data) {
			commit('removeToken', do_logout.data.data)
		}
	},
	async getMe({ commit, state }) {
		const get_me = await auth_api.me(state.auth_token)

		if (get_me.data) {
			commit('setMe', get_me.data.data)
		}
	},
}
