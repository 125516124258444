import axios from 'axios'
import Cookies from 'js-cookie'
import App from '../main.js'

const api_config = axios.create({
	baseURL:
		process.env.APP_ENV === 'development'
			? process.env.VUE_APP_API_URL_DEV
			: process.env.VUE_APP_API_URL_PROD,
})

api_config.interceptors.response.use(
	function(response) {
		return response
	},
	function(error) {
		if (error.response.status === 401 || error.response.status === 403) {
			App.$swal.fire({
				icon: 'warning',
				title: 'Sesi login Kamu telah habis, harap login lagi.',
				showConfirmButton: false,
				timer: 2000,
			})
			Cookies.remove('x-login')
			window.location = '/login'
		} else if (error.response.status === 500) {
			App.$swal.fire({
				icon: 'error',
				title: 'Terjadi kesalahan pada sistem, harap coba lagi.',
				showConfirmButton: false,
				timer: 2000,
			})
		}
		return Promise.reject(error)
	},
)

export default api_config
