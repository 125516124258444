import api_config from '../config/api'

export default {
	async getAbout(lang) {
		return api_config.get(`api/about/${lang}`)
	},
	async updateAbout({ id, form_data }, token) {
		return api_config.post(`api/about/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
