import category_api from '../../../apis/category'

export default {
	async addCategory({ rootGetters }, form_data) {
		return await category_api
			.addCategory(form_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
	async deleteCategory({ rootGetters }, id) {
		await category_api.deleteCategory(id, rootGetters['AUTH/auth_token'])
	},
	async getCategories({ commit }) {
		let categories_ind = await category_api.getCategories('ind')
		categories_ind = categories_ind.data.data
		let categories_eng = await category_api.getCategories('eng')
		categories_eng = categories_eng.data.data

		let categories = []
		if (categories_ind.length > 0) {
			categories = categories_ind.map(item => {
				const find_eng = categories_eng.find(
					item_eng => item_eng.id === item.id,
				)
				return {
					...item,
					name_eng: find_eng.name,
					name_ind: item.name,
				}
			})
		}

		commit('setCategories', categories)
	},
	async updateCategory({ rootGetters }, update_data) {
		return await category_api
			.updateCategory(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
