<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_data"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<div class="item-list x-large mt-16">
			<div
				v-if="!isEmpty(state_why_edim)"
				class="item border multi-data md:flex"
			>
				<div class="image-wrapper">
					<img :src="state_why_edim.image" />
				</div>
				<div class="text-wrapper">
					<div class="text-col">
						<p class="title">Bahasa Indonesia</p>
						<div
							class="text"
							v-html="state_why_edim.ind.description"
						></div>
					</div>
					<div class="text-col">
						<p class="title">Bahasa Inggris</p>
						<div
							class="text"
							v-html="state_why_edim.eng.description"
						></div>
					</div>
					<div class="action-col item-right">
						<button
							class="action edit"
							@click="doEdit(state_why_edim)"
						>
							Edit
						</button>
					</div>
				</div>
			</div>
			<div v-else class="no-data"></div>
		</div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../../components/partial/_Modal-text.vue'
	export default {
		data() {
			return {
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'x-large',
					inputs: [
						{
							name: 'description_ind',
							title: 'Deskripsi indonesia',
							type: 'rte',
						},
						{
							name: 'description_eng',
							title: 'Deskripsi inggris',
							type: 'rte',
						},
					],
					file_inputs: [
						{
							name: 'image',
							title: 'Gambar',
						},
					],
					multi_column: true,
					multi_image: false,
					store: {
						get: 'getWhyEdim',
						message: 'mengapa edim',
						prefix: 'WHY_EDIM',
						update: 'updateWhyEdim',
					},
					title: '$action Mengapa Edim',
				},
				selected_data: null,
			}
		},
		components: {
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_why_edim: 'WHY_EDIM/why_edim',
			}),
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_data = null
			},
			doEdit(data) {
				this.selected_data = {
					id: data.ind.id,
					description_eng: data.eng.description,
					description_ind: data.ind.description,
					image: data.image,
				}
				this.is_modal_hidden = false
			},
			init() {
				this.$store.dispatch('WHY_EDIM/getWhyEdim').then(() => {
					this.is_loading = false
				})
			},
		},
		mounted() {
			this.init()
		},
	}
</script>
