import api_config from '../config/api'

export default {
	async addOurClient(form_data, token) {
		return api_config.post('api/our_client', form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
	},
	async deleteOurClient(id, token) {
		return api_config.delete(`api/our_client/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	getOurClients() {
		return api_config.get('api/our_client')
	},
	async updateOurClient({ id, form_data }, token) {
		return api_config.post(`api/our_client/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
	},
}
