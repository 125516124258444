<template>
	<nav
		class="
			md:left-0
			md:block
			md:fixed
			md:top-0
			md:bottom-0
			md:overflow-y-auto
			md:flex-row md:flex-nowrap
			md:overflow-hidden
			md:w-64
			flex flex-wrap
			items-center
			justify-between
			relative
			z-10
			py-4
			sidebar-container
		"
	>
		<div
			class="
				md:flex-col
				md:items-stretch
				md:min-h-full
				md:flex-nowrap
				px-0
				flex flex-wrap
				items-center
				justify-between
				w-full
				mx-auto
			"
		>
			<!-- Toggler -->
			<button
				class="
					cursor-pointer
					text-black
					opacity-50
					md:hidden
					px-3
					py-1
					text-xl
					leading-none
					bg-transparent
					rounded
					border border-solid border-transparent
					burger-mobile
				"
				type="button"
				v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
			>
				<i class="fas fa-bars"></i>
			</button>
			<!-- Brand -->
			<div
				class="
					md:block
					text-left
					md:pb-2
					text-blueGray-600
					mr-0
					inline-block
					whitespace-nowrap
					text-sm
					uppercase
					font-bold
					p-4
					px-6
				"
			>
				<img :src="edim_logo" class="edim-logo hidden md:block" />
				<div
					class="
						text-sm text-white
						w-full
						mt-8
						profile-wrapper
						hidden
						md:block
					"
				>
					<img
						alt="..."
						class="
							w-1/2
							rounded-full
							align-middle
							shadow-lg
							profile-image
						"
						src="../assets/img/team-1-800x800.jpg"
					/>
					<div
						class="profile-name-wrapper"
						:class="{
							'show-dropdown': !is_profile_menu_hidden,
						}"
						v-if="!isEmpty(state_me)"
					>
						<div class="profile-name" @click="handleProfileClick()">
							{{ state_me.name }}
							<i class="fas fa-angle-down"></i>
						</div>
						<div class="profile-dropdown">
							<button class="signout" @click="doLogout()">
								Keluar <i class="fas fa-sign-out-alt"></i>
							</button>
						</div>
					</div>
					<div class="profile-title" v-if="!isEmpty(state_me)">
						{{ getUserRole }}
					</div>
				</div>
			</div>
			<!-- User -->
			<ul class="md:hidden items-center flex flex-wrap list-none">
				<li class="inline-block relative">
					<user-dropdown-component
						:user_data="state_me"
						:user_role="getUserRole"
						v-if="state_me"
						@logout="doLogout()"
					></user-dropdown-component>
				</li>
			</ul>
			<!-- Collapse -->
			<div
				class="
					md:flex md:flex-col
					md:items-stretch
					md:opacity-100
					md:relative
					md:mt-4
					md:shadow-none
					shadow
					absolute
					top-0
					left-0
					right-0
					z-40
					overflow-y-auto overflow-x-hidden
					h-auto
					items-center
					flex-1
					rounded
					sidebar-wrapper-mobile
				"
				v-bind:class="collapseShow"
			>
				<!-- Collapse header -->
				<div
					class="
						md:min-w-full
						md:hidden
						block
						pb-4
						mb-4
						border-b border-solid border-blueGray-200
					"
				>
					<div class="flex flex-wrap">
						<div class="w-6/12 flex justify-start">
							<img :src="edim_logo" class="edim-logo ml-0" />
						</div>
						<div class="w-6/12 flex justify-end">
							<button
								type="button"
								class="
									cursor-pointer
									text-white
									md:hidden
									px-3
									py-1
									text-xl
									leading-none
									bg-transparent
									rounded
									border border-solid border-transparent
									outline-0
								"
								v-on:click="toggleCollapseShow('hidden')"
							>
								<i class="fas fa-times"></i>
							</button>
						</div>
					</div>
				</div>
				<!-- Navigation -->
				<ul
					class="
						md:flex-col
						md:min-w-full
						flex flex-col
						list-none
						sidebar-menu
					"
					v-if="!isEmpty(state_me)"
				>
					<li
						class="items-center dropdown"
						:class="{
							active:
								currentRoute.meta.group &&
								currentRoute.meta.group === 'dashboard',
							'show-dropdown':
								dropdown_menu_shown === 'dashboard',
						}"
					>
						<a
							class="py-3 block"
							@click="handleDashboardMenuClick('dashboard')"
							><i class="fas fa-home opacity-75 mr-2 text-sm"></i>
							Beranda</a
						>
						<i class="fas fa-angle-down caret"></i>
						<div class="menu-dropdown">
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'banner',
								}"
								to="/dashboard/banner"
								>Baner</router-link
							>
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'why-edim',
								}"
								to="/dashboard/why-edim"
								>Mengapa EDIM</router-link
							>
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'specialist',
								}"
								to="/dashboard/specialist"
								>Spesialis</router-link
							>
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'project',
								}"
								to="/dashboard/project"
								>Proyek</router-link
							>
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'our-client',
								}"
								to="/dashboard/our-client"
								>Klien Kami</router-link
							>
						</div>
					</li>
					<li
						class="items-center dropdown"
						:class="{
							active:
								currentRoute.meta.group &&
								currentRoute.meta.group === 'product',
							'show-dropdown': dropdown_menu_shown === 'product',
						}"
					>
						<a
							class="py-3 block"
							@click="handleDashboardMenuClick('product')"
							><i class="fas fa-box opacity-75 mr-2 text-sm"></i>
							Produk</a
						>
						<i class="fas fa-angle-down caret"></i>
						<div class="menu-dropdown">
							<router-link
								class="menu"
								:class="{
									active:
										currentRoute.name === 'product-list',
								}"
								to="/product/list"
								>List Produk</router-link
							>
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'category',
								}"
								to="/product/category"
								>Kategori</router-link
							>
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'catalog',
								}"
								to="/product/catalog"
								>Katalog</router-link
							>
						</div>
					</li>
					<li
						class="items-center dropdown"
						:class="{
							active:
								currentRoute.meta.group &&
								currentRoute.meta.group === 'about',
							'show-dropdown': dropdown_menu_shown === 'about',
						}"
					>
						<a
							class="py-3 block"
							@click="handleDashboardMenuClick('about')"
							><i
								class="fas fa-building opacity-75 mr-2 text-sm"
							></i>
							Tentang</a
						>
						<i class="fas fa-angle-down caret"></i>
						<div class="menu-dropdown">
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'about-us',
								}"
								to="/about/about-us"
								>Tentang Kami</router-link
							>
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'certificate',
								}"
								to="/about/certificate"
								>Sertifikat</router-link
							>
							<router-link
								class="menu"
								:class="{
									active: currentRoute.name === 'our-team',
								}"
								to="/about/our-team"
								>Tim Kami</router-link
							>
							<router-link
								class="menu"
								:class="{
									active:
										currentRoute.name === 'vision-mission',
								}"
								to="/about/vision-mission"
								>Visi Misi</router-link
							>
						</div>
					</li>
					<li
						class="items-center"
						:class="{
							active: currentRoute.name === 'contact',
						}"
					>
						<router-link class="py-3 block" to="/contact"
							><i class="fas fa-user opacity-75 mr-2 text-sm"></i
							>kontak</router-link
						>
					</li>
					<li
						class="items-center"
						:class="{
							active: currentRoute.name === 'career',
						}"
					>
						<router-link class="py-3 block" to="/career"
							><i class="fas fa-users opacity-75 mr-2 text-sm"></i
							>Karir</router-link
						>
					</li>
					<li
						class="items-center"
						:class="{
							active: currentRoute.name === 'footer',
						}"
					>
						<router-link class="py-3 block" to="/footer"
							><i
								class="
									fas
									fa-shoe-prints
									opacity-75
									mr-2
									text-sm
								"
							></i
							>Footer</router-link
						>
					</li>
					<li
						class="items-center"
						:class="{
							active: currentRoute.name === 'user-management',
						}"
						v-if="state_me.role_id < 2"
					>
						<router-link class="py-3 block" to="/user-management"
							><i class="fas fa-users opacity-75 mr-2 text-sm"></i
							>Manajemen user</router-link
						>
					</li>
				</ul>
			</div>
		</div>
	</nav>
</template>
<script>
import edim_logo from '../assets/img/edim-logo.png'
import UserDropdownComponent from './UserDropdown.vue'
import { mapGetters } from 'vuex'
export default {
	data() {
		return {
			collapseShow: 'hidden',
			dropdown_menu_shown: '',
			is_profile_menu_hidden: true,
			edim_logo: edim_logo,
		}
	},
	computed: {
		...mapGetters({
			state_me: 'AUTH/me',
		}),
		currentRoute() {
			return this.$route
		},
		getUserRole() {
			return this.state_me.role_id === 1 ? 'Super Admin' : 'Admin'
		},
	},
	methods: {
		doLogout() {
			this.$store
				.dispatch('AUTH/doLogout')
				.then(() => {
					this.$swal
						.fire({
							icon: 'success',
							title: 'Berhasil keluar.',
							showConfirmButton: false,
							timer: 1500,
						})
						.then(() => {
							this.$router.push({
								name: 'login',
							})
						})
				})
				.catch(() => {
					this.$swal.fire({
						icon: 'error',
						title: 'Terjadi kesalahan pada sistem, harap coba lagi.',
						showConfirmButton: false,
						timer: 2000,
					})
				})
		},
		handleDashboardMenuClick(menu) {
			if (this.dropdown_menu_shown === menu) {
				this.dropdown_menu_shown = ''
			} else {
				this.dropdown_menu_shown = menu
			}
		},
		handleProfileClick() {
			this.is_profile_menu_hidden = !this.is_profile_menu_hidden
		},
		toggleCollapseShow(classes) {
			this.collapseShow = classes
		},
	},
	components: {
		UserDropdownComponent,
	},
	mounted() {
		if (this.$route.meta.group) {
			this.dropdown_menu_shown = this.$route.meta.group
		}

		this.$store.dispatch('AUTH/getMe').catch(() => {
			this.$swal.fire({
				icon: 'error',
				title: 'Terjadi kesalahan pada sistem, harap coba lagi.',
				showConfirmButton: false,
				timer: 2000,
			})
		})
	},
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/template/_sidebar.scss';
</style>
