import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'
import Cookies from 'js-cookie'

export default {
	namespaced: true,
	state() {
		return {
			auth_token: Cookies.get('x-login') || null,
			me: null,
		}
	},
	mutations,
	actions,
	getters,
}
