import api_config from '../config/api'

export default {
	async addUser(form_data, token) {
		return api_config.post('api/user', form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	async deleteUser(id, token) {
		return api_config.delete(`api/user/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	getUsers(token) {
		return api_config.get(`api/user`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	async updateUser({ id, form_data }, token) {
		return api_config.post(`api/user/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
