<template>
	<div>
		<a
			class="text-blueGray-500 block"
			href="#pablo"
			v-on:click="toggleDropdown($event)"
			ref="btnDropdownRef"
		>
			<div class="items-center flex mr-2">
				<span
					class="
						w-12
						h-12
						text-sm text-white
						bg-blueGray-200
						inline-flex
						items-center
						justify-center
						rounded-full
					"
				>
					<img
						alt="..."
						class="
							w-full
							rounded-full
							align-middle
							border-none
							shadow-lg
						"
						src="../assets/img/team-1-800x800.jpg"
					/>
				</span>
			</div>
		</a>
		<div
			ref="popoverDropdownRef"
			class="
				bg-white
				text-base
				z-50
				float-left
				py-2
				list-none
				text-left
				rounded
				shadow-lg
				mt-1
			"
			v-bind:class="{
				hidden: !dropdownPopoverShow,
				block: dropdownPopoverShow,
			}"
			style="min-width: 12rem"
		>
			<p
				class="
					text-sm
					py-2
					px-4
					font-normal
					block
					w-full
					whitespace-nowrap
					bg-transparent
					text-blueGray-700
				"
			>
				{{ user_data.name }}
			</p>
			<p
				class="
					text-sm
					py-2
					px-4
					font-normal
					block
					w-full
					whitespace-nowrap
					bg-transparent
					text-blueGray-700
				"
			>
				{{ user_role }}
			</p>
			<div class="h-0 my-2 border border-solid border-blueGray-100" />
			<a
				class="
					text-sm
					py-2
					px-4
					font-normal
					block
					w-full
					whitespace-nowrap
					bg-transparent
					text-red-700
				"
				v-on:click="$emit('logout')"
			>
				Keluar
			</a>
		</div>
	</div>
</template>
<script>
import { createPopper } from '@popperjs/core'

export default {
	emits: ['logout'],
	props: ['user_data', 'user_role'],
	data() {
		return {
			dropdownPopoverShow: false,
		}
	},
	methods: {
		toggleDropdown: function (event) {
			event.preventDefault()
			if (this.dropdownPopoverShow) {
				this.dropdownPopoverShow = false
			} else {
				this.dropdownPopoverShow = true
				createPopper(
					this.$refs.btnDropdownRef,
					this.$refs.popoverDropdownRef,
					{
						placement: 'bottom-end',
					},
				)
			}
		},
	},
}
</script>
