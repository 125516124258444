import our_specialist_api from '../../../apis/our-specialist'

export default {
	async getSpecialists({ commit }) {
		const our_specialists_ind = await our_specialist_api.getSpecialists(
			'ind',
		)

		const our_specialists_eng = await our_specialist_api.getSpecialists(
			'eng',
		)

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		const temp = {}

		if (our_specialists_ind.data.data.length > 0) {
			our_specialists_ind.data.data.forEach(item => {
				if (item.projects.length > 0) {
					item.projects = item.projects.map(project => ({
						...project,
						image: `${api_uri}${item.image}`,
					}))
				}
				const find_eng = our_specialists_eng.data.data.find(
					item_eng => item_eng.id === item.id,
				)
				temp[item.id] = {
					eng: find_eng,
					id: item.id,
					image: `${api_uri}${item.image}`,
					ind: item,
					projects: item.projects,
				}
			})
		}

		commit('setSpecialists', temp)
	},
	async addSpecialist({ rootGetters }, update_data) {
		return await our_specialist_api
			.addSpecialist(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
	async deleteSpecialist({ rootGetters }, id) {
		await our_specialist_api.deleteSpecialist(
			id,
			rootGetters['AUTH/auth_token'],
		)
	},
	async updateSpecialist({ rootGetters }, update_data) {
		return await our_specialist_api
			.updateSpecialist(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
