<template>
	<div class="table-wrapper">
		<table :class="options.size">
			<thead>
				<tr>
					<th v-bind:key="header" v-for="header in options.columns">
						{{ header.name }}
					</th>
				</tr>
			</thead>
			<tbody v-if="data.length > 0">
				<tr v-bind:key="item" v-for="(item, idx) in data">
					<template
						v-bind:key="item_column"
						v-for="item_column in options.columns"
					>
						<td v-if="item_column.value === 'increment'">
							{{ idx + 1 }}
						</td>
						<td v-else-if="item_column.value === 'action'">
							<button
								class="action edit"
								v-if="options.can_edit"
								v-on:click="$emit('edit', item)"
							>
								<i class="fa fa-pencil-alt"></i>
							</button>
							<button
								class="action delete"
								@click="doDelete(item.id)"
								v-if="
									options.can_delete &&
									deleting_list.indexOf(item.id) < 0
								"
							>
								<i class="fa fa-trash"></i>
							</button>
							<button
								class="action delete"
								v-else-if="
									options.can_delete &&
									deleting_list.indexOf(item.id) >= 0
								"
							>
								<i class="fas fa-circle-notch fa-spin"></i>
							</button>
						</td>
						<td v-else>
							{{ item[item_column.value] }}
						</td>
					</template>
				</tr>
			</tbody>
			<tbody v-else>
				<tr>
					<td :colspan="options.columns.length">
						Tidak ada {{ options.store.message }}...
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>
<script>
export default {
	emits: ['init_data'],
	props: ['data', 'options'],
	data: function () {
		return {
			deleting_list: [],
		}
	},
	methods: {
		doDelete(id) {
			this.deleting_list.push(id)
			this.$store
				.dispatch(
					`${this.options.store.prefix}/${this.options.store.delete}`,
					id,
				)
				.then(() => {
					this.$swal
						.fire({
							icon: 'success',
							title: `Berhasil menghapus ${this.options.store.message}.`,
							showConfirmButton: false,
							timer: 1500,
						})
						.then(() => {
							const index_item = this.deleting_list.indexOf(id)
							delete this.deleting_list[index_item]
							this.$emit('init_data')
						})
				})
				.catch(() => {
					this.$swal.fire({
						icon: 'error',
						title: 'Terjadi kesalahan pada sistem, harap coba lagi.',
						showConfirmButton: false,
						timer: 2000,
					})
				})
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/template/partial/_table.scss';
</style>