<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_data"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<div class="action-tab md:flex">
			<button class="btn-add" @click="doAddCareer()">
				<i class="fa fa-plus"></i>Tambah
			</button>
			<div class="tab-container">
				<button
					class="tab"
					:class="{ active: selected_language === 'ind' }"
					@click="selected_language = 'ind'"
				>
					Indonesia
				</button>
				<button
					class="tab"
					:class="{ active: selected_language === 'eng' }"
					@click="selected_language = 'eng'"
				>
					Inggris
				</button>
			</div>
		</div>
		<div v-if="!isEmpty(state_careers)" class="item-list mt-16 career">
			<div
				v-bind:key="career"
				v-for="career in state_careers"
				class="item"
			>
				<img class="banner" :src="career.image" />
				<div class="text-wrapper">
					<span class="title-career">{{
						career[selected_language].position
					}}</span>
					<span class="slot"
						>{{ career[selected_language].quantity }}
						{{
							selected_language === 'ind' ? 'Posisi' : 'Position'
						}}</span
					>
					<span class="requirement">{{
						career[selected_language].description
					}}</span>
					<span class="gender">{{
						career[selected_language].sex
					}}</span>

					<div class="action-col item-between">
						<button
							class="action edit"
							@click="doEditCareer(career)"
						>
							Edit
						</button>
						<button
							class="action delete"
							@click="doDeleteCareer(career.id)"
							v-if="deleting_list.indexOf(career.id) < 0"
						>
							<i class="fa fa-trash"></i>
						</button>
						<button class="action processing" v-else>
							<i class="fas fa-circle-notch fa-spin"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-data"></div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../components/partial/_Modal-text.vue'
	export default {
		data() {
			return {
				deleting_list: [],
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'x-large',
					inputs: [
						{
							name: 'position_ind',
							title: 'Posisi Indonesia',
							type: 'text',
						},
						{
							name: 'position_eng',
							title: 'Posisi Inggris',
							type: 'text',
						},
						{
							name: 'quantity',
							title: 'Jumlah Posisi',
							type: 'number',
						},
						{
							name: 'description_ind',
							title: 'Pendidikan Indonesia',
							type: 'text',
						},
						{
							name: 'description_eng',
							title: 'Pendidikan Inggris',
							type: 'text',
						},
						{
							name: 'sex_ind',
							title: 'Jenis Kelamin Indonesia',
							type: 'text',
						},
						{
							name: 'sex_eng',
							title: 'Jenis Kelamin Inggris',
							type: 'text',
						},
						{
							name: 'responsibility_ind',
							title: 'Tanggung Jawab Indonesia',
							type: 'rte',
						},
						{
							name: 'responsibility_eng',
							title: 'Tanggung Jawab Inggris',
							type: 'rte',
						},
						{
							name: 'requirement_ind',
							title: 'Persayaratan Indonesia',
							type: 'rte',
						},
						{
							name: 'requirement_eng',
							title: 'Persayaratan Inggris',
							type: 'rte',
						},
						{
							name: 'facility_ind',
							title: 'Fasilitas Indonesia',
							type: 'rte',
						},
						{
							name: 'facility_eng',
							title: 'Fasilitas Inggris',
							type: 'rte',
						},
						{
							name: 'mail_to',
							title: 'Email',
							type: 'text',
						},
					],
					file_inputs: [
						{
							name: 'image',
							title: 'Gambar',
						},
					],
					multi_column: true,
					multi_image: false,
					store: {
						add: 'addCareer',
						get: 'getCareers',
						message: 'karir',
						prefix: 'CAREER',
						update: 'updateCareer',
					},
					title: '$action Karir',
				},
				selected_data: null,
				selected_language: 'ind',
			}
		},
		components: {
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_careers: 'CAREER/careers',
			}),
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_data = null
			},
			doAddCareer() {
				this.is_modal_hidden = false
			},
			doDeleteCareer(id) {
				this.deleting_list.push(id)
				this.$store
					.dispatch('CAREER/deleteCareer', id)
					.then(() => {
						this.$swal
							.fire({
								icon: 'success',
								title: 'Berhasil menghapus karir.',
								showConfirmButton: false,
								timer: 1500,
							})
							.then(() => {
								const index_item = this.deleting_list.indexOf(
									id,
								)
								delete this.deleting_list[index_item]
								this.init()
							})
					})
					.catch(() => {
						this.$swal.fire({
							icon: 'error',
							title:
								'Terjadi kesalahan pada sistem, harap coba lagi.',
							showConfirmButton: false,
							timer: 2000,
						})
					})
			},
			doEditCareer(data) {
				this.selected_data = {
					id: data.id,
					image: data.image,
					quantity: data.ind.quantity,
					position_ind: data.ind.position,
					description_ind: data.ind.description,
					responsibility_ind: data.ind.responsibility,
					requirement_ind: data.ind.requirement,
					facility_ind: data.ind.facility,
					position_eng: data.eng.position,
					description_eng: data.eng.description,
					responsibility_eng: data.eng.responsibility,
					requirement_eng: data.eng.requirement,
					facility_eng: data.eng.facility,
					sex: data.ind.sex,
					mail_to: data.ind.mail_to,
				}
				this.is_modal_hidden = false
			},
			init() {
				this.$store.dispatch('CAREER/getCareers')
				this.is_loading = false
			},
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/career.scss';
</style>
