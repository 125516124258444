import api_config from '../config/api'

export default {
	async addSpecialist(form_data, token) {
		return api_config.post('api/our_specialist', form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	async deleteSpecialist(id, token) {
		return api_config.delete(`api/our_specialist/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	getSpecialists(language) {
		return api_config.get(`api/our_specialist/${language}`)
	},
	async updateSpecialist({ id, form_data }, token) {
		return api_config.post(`api/our_specialist/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
