<template>
	<div v-if="state_contact">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_data"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<div class="item-list mt-16">
			<div class="item border multi-data contact md:flex">
				<div class="image-wrapper">
					<img :src="state_contact.image" />
					<div
						class="address"
						v-html="state_contact.address"
						@click="handleOpenAddress"
					></div>
				</div>
				<div class="text-wrapper">
					<div class="text-col">
						<div class="icon-container">
							<i class="fas fa-user"></i>
						</div>
						<div class="text-container">
							<p class="title">Nama</p>
							<div class="text" v-html="state_contact.name"></div>
						</div>
					</div>
					<div class="text-col">
						<div class="icon-container">
							<i class="fas fa-phone"></i>
						</div>
						<div class="text-container">
							<p class="title">Telepon</p>
							<p class="text">{{ state_contact.phone }}</p>
						</div>
					</div>
					<div class="text-col">
						<div class="icon-container">
							<i class="fas fa-envelope"></i>
						</div>
						<div class="text-container">
							<p class="title">Email</p>
							<p class="text">{{ state_contact.mail }}</p>
						</div>
					</div>
					<div class="text-col">
						<div class="icon-container">
							<i class="fab fa-linkedin"></i>
						</div>
						<div class="text-container">
							<p class="title">Linkedin</p>
							<a
								class="text"
								:href="state_contact.linkedin"
								target="_blank"
							>
								@{{ state_contact.name }}
							</a>
						</div>
					</div>
					<div class="action-col item-center">
						<button
							class="action edit"
							@click="doEdit(state_contact)"
						>
							Edit
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import modal from '../components/partial/_Modal-text.vue'
export default {
	data() {
		return {
			is_modal_hidden: true,
			modal_options: {
				container_class: 'x-large',
				inputs: [
					{
						name: 'name',
						title: 'Nama',
						type: 'text',
					},
					{
						name: 'phone',
						title: 'Nomor Telepon',
						type: 'text',
					},
					{
						name: 'mail',
						title: 'Email',
						type: 'text',
					},
					{
						name: 'linkedin',
						title: 'URL Linkedin',
						type: 'text',
					},
					{
						name: 'address',
						title: 'Alamat',
						type: 'textarea',
					},
					{
						name: 'google_direction',
						title: 'URL Google Maps',
						type: 'text',
					},
				],
				file_inputs: [
					{
						name: 'image',
						title: 'Gambar',
					},
				],
				multi_column: true,
				multi_image: false,
				store: {
					get: 'getContact',
					message: 'kontak',
					prefix: 'CONTACT',
					update: 'updateContact',
				},
				title: '$action Kontak',
			},
			selected_data: null,
		}
	},
	components: {
		'my-modal': modal,
	},
	computed: {
		...mapGetters({
			state_contact: 'CONTACT/contact_data',
		}),
	},
	methods: {
		closeModal() {
			this.is_modal_hidden = true
			this.selected_data = null
		},
		doEdit(data) {
			this.selected_data = data
			this.is_modal_hidden = false
		},
		handleOpenAddress() {
			window.open(this.state_contact.google_direction, '_blank')
		},
		init() {
			this.$store.dispatch('CONTACT/getContact')
		},
	},
	mounted() {
		this.init()
	},
}
</script>