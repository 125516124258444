import vision_mission_api from '../../../apis/vision-mission'

export default {
	async getVisionMission({ commit }) {
		const mission_ind = await vision_mission_api.getMission('ind')
		const mission_eng = await vision_mission_api.getMission('eng')
		const vision_ind = await vision_mission_api.getVision('ind')
		const vision_eng = await vision_mission_api.getVision('eng')

		let vision_mission = null
		if (mission_ind.data.data && vision_ind.data.data) {
			vision_mission = {
				mission: {
					id: mission_ind.data.data.id,
					ind: mission_ind.data.data.description,
					eng: mission_eng.data.data.description,
				},
				vision: {
					id: vision_ind.data.data.id,
					ind: vision_ind.data.data.description,
					eng: vision_eng.data.data.description,
				},
			}
			commit('setMissionVision', vision_mission)
		}
	},
	async updateVisionMission({ rootGetters }, update_data) {
		return await vision_mission_api
			.updateVisionMission(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
