import project_api from '../../../apis/project'

export default {
	async addProject({ rootGetters }, update_data) {
		return await project_api
			.addProject(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
	async deleteProject({ rootGetters }, id) {
		await project_api.deleteProject(id, rootGetters['AUTH/auth_token'])
	},
	async getProjects({ commit }) {
		const projects = await project_api.getProjects()

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		const temp = {}

		if (projects.data.data.length > 0) {
			projects.data.data = projects.data.data
				.map(item => ({
					...item,
					image: `${api_uri}${item.image}`,
				}))
				.forEach(career => {
					temp[career.id] = career
				})
		}

		commit('setProjects', temp)
	},
	async updateProject({ rootGetters }, update_data) {
		return await project_api
			.updateProject(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
