import footer_api from '../../../apis/footer'

export default {
	async getFooters({ commit }) {
		let footer_data = await footer_api.getFooter()
		footer_data = footer_data.data.data

		commit('setFooters', footer_data)
	},
	async updateFooter({ rootGetters }, update_data) {
		return await footer_api
			.updateFooter(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
