<template>
	<div v-if="state_users">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_user"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<button class="btn-add" @click="doAddUser()">
			<i class="fa fa-plus"></i>Tambah
		</button>
		<div class="mt-16">
			<my-table
				@init_data="init()"
				@edit="doEditUser"
				:data="state_users"
				:options="table_options"
				v-if="state_users"
			></my-table>
		</div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import modal from '../components/partial/_Modal-text.vue'
import table from '../components/partial/_Table.vue'
export default {
	data() {
		return {
			is_modal_hidden: true,
			modal_options: {
				container_class: 'small',
				inputs: [
					{
						name: 'sex',
						type: 'hidden',
						value: 1,
					},
					{
						name: 'username',
						title: 'Username',
						type: 'text',
					},
					{
						name: 'name',
						title: 'Nama',
						type: 'text',
					},
					{
						name: 'password',
						title: 'Password',
						type: 'password',
					},
					{
						name: 'email',
						title: 'Email',
						type: 'text',
					},
					{
						name: 'role_id',
						radio_values: [
							{
								name: 'Superadmin',
								value: 1,
							},
							{
								name: 'Admin',
								value: 2,
							},
						],
						title: 'Hak akses',
						type: 'radio',
					},
				],
				store: {
					add: 'addUser',
					get: 'getUsers',
					message: 'pengguna',
					prefix: 'USER_MANAGEMENT',
					update: 'updateUser',
				},
				title: '$action pengguna',
			},
			table_options: {
				columns: [
					{
						name: 'No',
						value: 'increment',
					},
					{
						name: 'Username',
						value: 'username',
					},
					{
						name: 'Email',
						value: 'email',
					},
					{
						name: 'Hak Akses',
						value: 'role',
					},
					{
						name: 'Aksi',
						value: 'action',
					},
				],
				can_delete: true,
				can_edit: true,
				size: 'large',
				store: {
					delete: 'deleteUser',
					edit: 'updateUser',
					message: 'pengguna',
					prefix: 'USER_MANAGEMENT',
				},
			},
			selected_user: null,
		}
	},
	components: {
		'my-modal': modal,
		'my-table': table,
	},
	computed: {
		...mapGetters({
			state_users: 'USER_MANAGEMENT/users',
		}),
	},
	methods: {
		closeModal() {
			this.is_modal_hidden = true
			this.selected_user = null
		},
		doAddUser() {
			this.is_modal_hidden = false
		},
		doEditUser(user) {
			this.selected_user = user
			this.is_modal_hidden = false
		},
		init() {
			this.$store.dispatch('USER_MANAGEMENT/getUsers')
		},
	},
	mounted() {
		this.init()
	},
}
</script>