<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_data"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<button class="btn-add" @click="doAddProject()">
			<i class="fa fa-plus"></i>Tambah
		</button>
		<div v-if="!isEmpty(state_projects)" class="item-list mt-16 project">
			<div
				v-bind:key="project"
				v-for="project in state_projects"
				class="item"
			>
				<img class="banner" :src="project.image" />
				<div class="text-wrapper">
					<div class="detail">
						<p class="company">
							{{ project.company }}
						</p>
						<p class="title">
							{{ project.project_name }}
						</p>
						<p class="location">
							{{ project.project_loc }}
						</p>
						<p class="time">
							{{ project.project_period }}
						</p>
					</div>
					<div class="action-col item-between">
						<button
							class="action edit"
							@click="doEditProject(project)"
						>
							Edit
						</button>
						<button
							class="action delete"
							@click="doDeleteProject(project.id)"
							v-if="deleting_list.indexOf(project.id) < 0"
						>
							<i class="fa fa-trash"></i>
						</button>
						<button class="action processing" v-else>
							<i class="fas fa-circle-notch fa-spin"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-data"></div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../../components/partial/_Modal-text.vue'
	export default {
		data() {
			return {
				deleting_list: [],
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'large',
					inputs: [
						{
							name: 'company',
							title: 'Perusahaan',
							type: 'text',
						},
						{
							name: 'project_name',
							title: 'Nama Proyek',
							type: 'text',
						},
						{
							name: 'project_loc',
							title: 'Lokasi',
							type: 'text',
						},
						{
							name: 'project_period',
							title: 'Periode Proyek',
							type: 'text',
						},
					],
					file_inputs: [
						{
							name: 'image',
							title: 'Gambar',
						},
					],
					multi_column: true,
					multi_image: false,
					store: {
						add: 'addProject',
						get: 'getProjects',
						message: 'proyek',
						prefix: 'PROJECT',
						update: 'updateProject',
					},
					title: '$action proyek',
				},
				selected_data: null,
			}
		},
		components: {
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_projects: 'PROJECT/projects',
			}),
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_data = null
			},
			doAddProject() {
				this.is_modal_hidden = false
			},
			doDeleteProject(id) {
				this.deleting_list.push(id)
				this.$store
					.dispatch('PROJECT/deleteProject', id)
					.then(() => {
						this.$swal
							.fire({
								icon: 'success',
								title: 'Berhasil menghapus proyek.',
								showConfirmButton: false,
								timer: 1500,
							})
							.then(() => {
								const index_item = this.deleting_list.indexOf(
									id,
								)
								delete this.deleting_list[index_item]
								this.init()
							})
					})
					.catch(() => {
						this.$swal.fire({
							icon: 'error',
							title:
								'Terjadi kesalahan pada sistem, harap coba lagi.',
							showConfirmButton: false,
							timer: 2000,
						})
					})
			},
			doEditProject(data) {
				this.selected_data = data
				this.is_modal_hidden = false
			},
			init() {
				this.$store.dispatch('PROJECT/getProjects')
				this.is_loading = false
			},
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/project.scss';
</style>
