import api_config from '../config/api'

export default {
	getFooter() {
		return api_config.get(`api/footer`)
	},
	async updateFooter({ id, form_data }, token) {
		return api_config.post(`api/footer/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
