import api_config from '../config/api'

export default {
	async addProject(form_data, token) {
		return api_config.post('api/project', form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	async deleteProject(id, token) {
		return api_config.delete(`api/project/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	getProjects() {
		return api_config.get('api/project')
	},
	async updateProject({ id, form_data }, token) {
		return api_config.post(`api/project/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
