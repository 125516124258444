<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_data"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<div class="action-tab">
			<button class="btn-add" @click="doAddProduct()">
				<i class="fa fa-plus"></i>Tambah
			</button>
			<div class="right-action">
				<div class="tab-container">
					<button
						class="tab"
						:class="{ active: selected_language === 'ind' }"
						@click="selected_language = 'ind'"
					>
						Indonesia
					</button>
					<button
						class="tab"
						:class="{ active: selected_language === 'eng' }"
						@click="selected_language = 'eng'"
					>
						Inggris
					</button>
				</div>
				<div class="search-input">
					<i class="fa fa-search"></i>
					<input
						type="text"
						v-model="search_key"
						placeholder="ketik disini..."
						v-on:keyup.enter="doSearchProduct"
					/>
					<button @click="doSearchProduct">Cari</button>
				</div>
			</div>
		</div>
		<div
			v-if="!isEmpty(state_products) && !isEmpty(filtered_products)"
			class="item-list mt-16 product"
		>
			<div
				v-bind:key="product"
				v-for="product in filtered_products"
				class="item"
			>
				<img class="banner" :src="product.images[0]" />
				<div class="text-wrapper">
					<div class="detail">
						<p class="category">
							{{ product[selected_language].category_name }}
						</p>
						<p class="product-title">
							{{ product[selected_language].name }}
						</p>
					</div>
					<div class="action-col item-between">
						<button
							class="action edit"
							@click="doEditProduct(product)"
						>
							Edit
						</button>
						<button
							class="action delete"
							@click="doDeleteProduct(product.id)"
							v-if="deleting_list.indexOf(product.id) < 0"
						>
							<i class="fa fa-trash"></i>
						</button>
						<button class="action processing" v-else>
							<i class="fas fa-circle-notch fa-spin"></i>
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-data"></div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../../components/partial/_Modal-text.vue'
	export default {
		data() {
			return {
				deleting_list: [],
				filtered_products: [],
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'x-large',
					inputs: [
						{
							name: 'is_featured',
							radio_values: [
								{
									name: 'Ya',
									value: 1,
								},
								{
									name: 'Tidak',
									value: 0,
								},
							],
							title: 'Unggulan',
							type: 'radio',
						},
						{
							name: 'name_ind',
							title: 'Nama produk Indonesia',
							type: 'text',
						},
						{
							name: 'name_eng',
							title: 'Nama produk Inggris',
							type: 'text',
						},
						{
							name: 'description_ind',
							title: 'Deskripsi Indonesia',
							type: 'rte',
						},
						{
							name: 'description_eng',
							title: 'Deskripsi Inggris',
							type: 'rte',
						},
						{
							name: 'detail_specification_ind',
							title: 'Detail Spesifikasi Indonesia',
							type: 'rte',
						},
						{
							name: 'detail_specification_eng',
							title: 'Detail Spesifikasi Inggris',
							type: 'rte',
						},
						{
							name: 'category_id',
							option_config: {
								label: 'name',
								value: 'id',
							},
							options: [],
							title: 'Kategori',
							type: 'singleselect',
						},
						{
							col: 'middle',
							name: 'video_1',
							title: 'Link video utama',
							type: 'text',
						},
						{
							col: 'middle',
							name: 'video_2',
							title: 'Link video 1',
							type: 'text',
						},
						{
							col: 'middle',
							name: 'video_3',
							title: 'Link video 2',
							type: 'text',
						},
						{
							col: 'middle',
							name: 'video_4',
							title: 'Link video 3',
							type: 'text',
						},
						{
							col: 'middle',
							name: 'video_5',
							title: 'Link video 4',
							type: 'text',
						},
					],
					file_inputs: [
						{
							name: 'image_1',
							title: 'Foto utama',
						},
						{
							name: 'image_2',
							title: 'Foto 1',
						},
						{
							name: 'image_3',
							title: 'Foto 2',
						},
						{
							name: 'image_4',
							title: 'Foto 3',
						},
						{
							name: 'image_5',
							title: 'Foto 4',
						},
					],
					first_title: 'Foto',
					middle_title: 'Video',
					multi_column: true,
					multi_image: true,
					store: {
						add: 'addProduct',
						get: 'getProducts',
						message: 'produk',
						prefix: 'PRODUCT',
						update: 'updateProduct',
					},
					title: '$action produk',
					triple_column: true,
				},
				search_key: '',
				selected_data: null,
				selected_language: 'ind',
			}
		},
		components: {
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_categories: 'CATEGORY/categories',
				state_products: 'PRODUCT/products',
			}),
		},
		watch: {
			state_products: {
				handler: function() {
					this.doSearchProduct()
				},
				deep: true,
			},
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_data = null
			},
			doAddProduct() {
				if (this.state_categories.length < 1) {
					this.$swal.fire({
						icon: 'info',
						title:
							'Tidak ada kategori, silahkan tambahkan kategori dahulu.',
						showConfirmButton: false,
						timer: 3000,
					})
				} else {
					this.is_modal_hidden = false
				}
			},
			doDeleteProduct(id) {
				this.deleting_list.push(id)
				this.$store
					.dispatch('PRODUCT/deleteProduct', id)
					.then(() => {
						this.$swal
							.fire({
								icon: 'success',
								title: 'Berhasil menghapus produk.',
								showConfirmButton: false,
								timer: 1500,
							})
							.then(() => {
								const index_item = this.deleting_list.indexOf(
									id,
								)
								delete this.deleting_list[index_item]
								this.init()
							})
					})
					.catch(() => {
						this.$swal.fire({
							icon: 'error',
							title:
								'Terjadi kesalahan pada sistem, harap coba lagi.',
							showConfirmButton: false,
							timer: 2000,
						})
					})
			},
			doEditProduct(data) {
				if (this.state_categories.length < 1) {
					this.$swal.fire({
						icon: 'info',
						title:
							'Tidak ada kategori, silahkan tambahkan kategori dahulu.',
						showConfirmButton: false,
						timer: 3000,
					})
				} else {
					this.selected_data = {
						id: data.ind.id,
						category_id: data.ind.category_id,
						is_featured: data.ind.is_featured,
						name_ind: data.ind.name,
						name_eng: data.eng.name,
						description_ind: data.ind.description,
						description_eng: data.eng.description,
						detail_specification_ind: data.ind.detail_specification,
						detail_specification_eng: data.eng.detail_specification,
					}

					if (data.images.length > 0) {
						this.selected_data.images = data.images
					}

					if (data.videos.length > 0) {
						data.videos.forEach((video, idx) => {
							this.selected_data[`video_${idx + 1}`] = video.video
						})
					}

					this.is_modal_hidden = false
				}
			},
			doSearchProduct() {
				if (this.search_key !== '') {
					this.filtered_products = Object.values(
						this.state_products,
					).filter(
						product =>
							String(product[this.selected_language].name)
								.toLowerCase()
								.indexOf(
									String(this.search_key).toLowerCase(),
								) > -1,
					)
				} else {
					this.filtered_products = this.state_products
				}
			},
			init() {
				this.$store.dispatch('CATEGORY/getCategories').then(() => {
					this.modal_options.inputs = this.modal_options.inputs.map(
						input => {
							if (input.name === 'category_id') {
								input.options = this.state_categories
							}
							return input
						},
					)
				})
				this.$store.dispatch('PRODUCT/getProducts').then(() => {
					this.filtered_products = this.state_products
				})
				this.is_loading = false
			},
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/product/product.scss';
</style>
