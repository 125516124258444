import why_edim_api from '../../../apis/why-edim'

export default {
	async getWhyEdim({ commit }) {
		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		let why_edim_eng = await why_edim_api.getWhyEdim('eng')
		let why_edim_ind = await why_edim_api.getWhyEdim('ind')
		let why_edim = null

		if (why_edim_ind.data.data) {
			why_edim = {
				eng: why_edim_eng.data.data[0],
				ind: why_edim_ind.data.data[0],
				image: `${api_uri}${why_edim_ind.data.data[0].image}`,
			}
		}

		commit('setWhyEdim', why_edim)
	},
	async updateWhyEdim({ rootGetters }, update_data) {
		return await why_edim_api
			.updateWhyEdim(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
