import catalog_api from '../../../apis/catalog'

export default {
	async getCatalog({ commit }) {
		let catalog_data = await catalog_api.getCatalog()
		catalog_data = catalog_data.data.data

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		catalog_data.files = `${api_uri}${catalog_data.files}`

		commit('setCatalog', catalog_data)
	},
	async updateCatalog({ rootGetters }, update_data) {
		return await catalog_api
			.updateCatalog(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
