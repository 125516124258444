import about_us from '../views/about/about-us.vue'
import banner from '../views/dashboard/banner.vue'
import career from '../views/career.vue'
import catalog from '../views/product/catalog.vue'
import category from '../views/product/category.vue'
import certificate from '../views/about/certificate.vue'
import contact from '../views/contact.vue'
import footer from '../views/footer.vue'
import login from '../views/login.vue'
import our_client from '../views/dashboard/our-client.vue'
import our_team from '../views/about/our-team.vue'
import product from '../views/product/product.vue'
import project from '../views/dashboard/project.vue'
import specialist from '../views/dashboard/specialist.vue'
import user_management from '../views/user-management.vue'
import vision_mission from '../views/about/vision-mission.vue'
import why_edim from '../views/dashboard/why-edim.vue'

const routes = [
	{
		path: '/about/about-us',
		name: 'about-us',
		component: about_us,
		meta: {
			group: 'about',
			menu_name: 'Tentang Kami',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/about/certificate',
		name: 'certificate',
		component: certificate,
		meta: {
			group: 'about',
			menu_name: 'Sertifikat',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/about/vision-mission',
		name: 'vision-mission',
		component: vision_mission,
		meta: {
			group: 'about',
			menu_name: 'Visi Misi',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/about/our-team',
		name: 'our-team',
		component: our_team,
		meta: {
			group: 'about',
			menu_name: 'Tim kami',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/career',
		name: 'career',
		component: career,
		meta: {
			menu_name: 'Karir',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/contact',
		name: 'contact',
		component: contact,
		meta: {
			menu_name: 'Kontak',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/footer',
		name: 'footer',
		component: footer,
		meta: {
			menu_name: 'Footer',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/dashboard/banner',
		name: 'banner',
		component: banner,
		meta: {
			group: 'dashboard',
			menu_name: 'baner',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/dashboard/our-client',
		name: 'our-client',
		component: our_client,
		meta: {
			group: 'dashboard',
			menu_name: 'klien kami',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/dashboard/project',
		name: 'project',
		component: project,
		meta: {
			group: 'dashboard',
			menu_name: 'Proyek',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/dashboard/specialist',
		name: 'specialist',
		component: specialist,
		meta: {
			group: 'dashboard',
			menu_name: 'spesialis',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/dashboard/why-edim',
		name: 'why-edim',
		component: why_edim,
		meta: {
			group: 'dashboard',
			menu_name: 'mengapa edim',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: login,
		meta: {
			need_auth: false,
			need_template: false,
		},
	},
	{
		path: '/product/catalog',
		name: 'catalog',
		component: catalog,
		meta: {
			group: 'product',
			menu_name: 'Katalog',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/product/category',
		name: 'category',
		component: category,
		meta: {
			group: 'product',
			menu_name: 'Kategori',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/product/list',
		name: 'product-list',
		component: product,
		meta: {
			group: 'product',
			menu_name: 'Produk',
			need_auth: true,
			need_template: true,
		},
	},
	{
		path: '/user-management',
		name: 'user-management',
		component: user_management,
		meta: {
			menu_name: 'Manajemen user',
			need_auth: true,
			need_template: true,
			role_id: 1,
		},
	},
]

export default routes
