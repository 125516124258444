<template>
	<div class="modal-mask" v-on:click="$emit('close')">
		<div class="modal-wrapper">
			<div
				class="modal-container"
				:class="options.container_class"
				@click.stop=""
			>
				<div class="modal-body">
					<p class="title" v-if="options.title">
						{{ getTitle(options.title) }}
					</p>
					<div class="image-preview">
						<img :src="getImage" v-if="getImage" />
						<div class="placeholder" v-else>
							<i class="fa fa-image"></i>
						</div>
					</div>
					<div class="form-wrapper image">
						<div class="text-col">
							<p class="title">Unggah gambar</p>
							<p class="description">
								Format gambar .jpg .jpeg .png
							</p>
						</div>
						<input
							accept=".jpg, .png, .jpeg"
							class="hidden"
							ref="input_image"
							type="file"
							@change="handleFileInput"
						/>
						<button
							class="btn-upload"
							@click="$refs.input_image.click()"
						>
							<i class="fa fa-camera"></i>Pilih gambar
						</button>
					</div>
					<div class="form-wrapper text" v-if="options.input_name">
						<div class="text-col">
							<p class="title">{{ options.input_name.title }}</p>
						</div>
						<input type="text" v-model="input_name" />
					</div>
				</div>
				<div class="modal-footer mt-8 mb-4">
					<button class="btn-cancel" v-on:click="$emit('close')">
						Batal
					</button>
					<button
						class="btn-save"
						:class="{ disabled: !can_save || is_uploading }"
						@click="handleSave"
					>
						Simpan
						<i
							class="fas fa-circle-notch fa-spin ml-1"
							v-if="is_uploading"
						></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		emits: ['close'],
		props: ['data', 'options'],
		data() {
			return {
				can_save: false,
				image_temp: null,
				input_name: this.data ? this.data.name : '',
				is_uploading: false,
			}
		},
		computed: {
			getImage() {
				return this.image_temp || this.data.image
			},
		},
		watch: {
			input_name: function(val) {
				if (val === '') {
					this.can_save = false
				}
			},
		},
		methods: {
			getTitle(title) {
				let action = 'Tambah'

				if (this.data.id) {
					action = 'Ubah'
				}

				return title.replace('$action', action)
			},
			handleFileInput(e) {
				if (e.target.files.length > 0) {
					this.image_temp = URL.createObjectURL(e.target.files[0])
					this.can_save = true
				} else {
					this.image_temp = null
					this.can_save = false
				}
			},
			handleSave() {
				if (this.can_save && !this.is_uploading) {
					const file_input = this.$refs.input_image
					if (this.getImage) {
						let do_process = true

						if (
							this.options.input_name &&
							(!this.input_name || this.input_name === '')
						) {
							do_process = false
						}

						if (do_process) {
							this.is_uploading = true
							const form_data = new FormData()

							if (file_input.files[0]) {
								form_data.append('image', file_input.files[0])
							}

							let name = this.data?.name || `Banner ${new Date()}`

							if (this.options.input_name) {
								name = this.input_name
							}

							form_data.append('name', name)

							let throw_data = form_data

							if (this.data.id) {
								throw_data = {
									id: this.data.id,
									form_data: form_data,
								}
							}

							this.$store
								.dispatch(
									`${this.options.store.prefix}/${
										this.data.id
											? this.options.store.update
											: this.options.store.add
									}`,
									throw_data,
								)
								.then(dt => {
									this.$store.dispatch(
										`${this.options.store.prefix}/${this.options.store.get}`,
									)
									this.$emit('close')
									this.$swal
										.fire({
											icon: dt.success
												? 'success'
												: 'error',
											title: `${
												dt.success
													? 'Berhasil'
													: 'Gagal'
											} ${
												this.data.id
													? 'merubah'
													: 'menambah'
											} ${this.options.store.message}.${
												dt.success ? '' : ` ${dt.msg}`
											}`,
											showConfirmButton: false,
											timer: dt.success ? 1500 : 3000,
										})
										.then(() => {
											this.is_uploading = false
										})
								})
								.catch(() => {
									this.is_uploading = false
									this.$swal.fire({
										icon: 'error',
										title:
											'Terjadi kesalahan pada sistem, harap coba lagi.',
										showConfirmButton: false,
										timer: 2000,
									})
								})
						} else {
							this.$swal.fire({
								icon: 'error',
								title: 'Nama tidak boleh kosong.',
								showConfirmButton: false,
								timer: 2000,
							})
						}
					} else if (!this.data) {
						this.$swal.fire({
							icon: 'error',
							title: 'Gambar tidak boleh kosong.',
							showConfirmButton: false,
							timer: 2000,
						})
					}
				}
			},
		},
		beforeMount() {
			if (this.data) {
				this.can_save = true
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/template/partial/_modal.scss';
</style>
