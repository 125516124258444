<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_data"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<div v-if="!isEmpty(state_our_team)" class="item-list x-large mt-16">
			<div class="item border">
				<div class="text-wrapper">
					<div class="text-col">
						<p class="title">Bahasa Indonesia</p>
						<div class="text" v-html="state_our_team.ind"></div>
					</div>
					<div class="text-col">
						<p class="title">Bahasa Inggris</p>
						<div class="text" v-html="state_our_team.eng"></div>
					</div>
					<div class="action-col item-center">
						<button
							class="action edit"
							@click="doEdit(state_our_team)"
						>
							Edit
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-data"></div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../../components/partial/_Modal-text.vue'
	export default {
		data() {
			return {
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'large',
					inputs: [
						{
							name: 'description_ind',
							title: 'Deskripsi indonesia',
							type: 'rte',
						},
						{
							name: 'description_eng',
							title: 'Deskripsi inggris',
							type: 'rte',
						},
					],
					multi_column: false,
					multi_image: false,
					store: {
						get: 'getOurTeam',
						message: 'tim kami',
						prefix: 'OUR_TEAM',
						update: 'updateOurTeam',
					},
					title: '$action Tim Kami',
				},
				selected_data: null,
			}
		},
		components: {
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_our_team: 'OUR_TEAM/our_team',
			}),
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_data = null
			},
			doEdit(data) {
				this.selected_data = {
					id: data.id,
					description_eng: data.eng,
					description_ind: data.ind,
				}
				this.is_modal_hidden = false
			},
			init() {
				this.$store.dispatch('OUR_TEAM/getOurTeam')
				this.is_loading = false
			},
		},
		mounted() {
			this.init()
		},
	}
</script>
