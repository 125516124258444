import api_config from '../config/api'

export default {
	async addCertificate(form_data, token) {
		return api_config.post('api/certificate', form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
	},
	async deleteCertificate(id, token) {
		return api_config.delete(`api/certificate/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	getCertificates() {
		return api_config.get('api/certificate')
	},
	async updateCertificate({ id, form_data }, token) {
		return api_config.post(`api/certificate/${id}`, form_data, {
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'multipart/form-data',
			},
		})
	},
}
