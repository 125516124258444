import api_config from '../config/api'

export default {
	async login(data) {
		return api_config.post('api/login', data)
	},
	async logout(token) {
		return api_config.post('api/logout', null, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
	async me(token) {
		return api_config.get('api/me', {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		})
	},
}
