<template>
	<div id="app">
		<sidebar-component
			v-if="currentRoute.meta.need_template"
		></sidebar-component>
		<div
			class="relative"
			:class="{ 'md:ml-64': currentRoute.meta.need_template }"
		>
			<div
				class="relative h-full"
				:class="{ 'content-wrapper': currentRoute.meta.need_template }"
			>
				<p
					class="menu-name capitalize"
					v-if="currentRoute.meta.need_template"
				>
					{{ currentRoute.meta.menu_name }}
				</p>
				<div
					class="mx-auto h-full w-full"
					:class="{
						'sm:px-4': currentRoute.meta.need_template,
						'py-10': currentRoute.meta.need_template,
					}"
				>
					<router-view />
					<div></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SidebarComponent from './components/_Sidebar.vue'
export default {
	name: 'dashboard-page',
	components: {
		SidebarComponent,
	},
	computed: {
		currentRoute() {
			return this.$route
		},
	},
	data() {
		return {
			date: new Date().getFullYear(),
		}
	},
}
</script>