<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_data"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<div v-if="!isEmpty(state_catalog)" class="item-list catalog mt-16">
			<div class="item">
				<div class="text-wrapper">
					<div class="detail">
						<object
							class="pdf-viewer"
							:data="state_catalog.files"
							type="application/pdf"
						>
							<embed
								:src="state_catalog.files"
								type="application/pdf"
							/>
						</object>
					</div>
					<div class="action-col item-right">
						<button
							class="action edit"
							@click="doEditCatalog(state_catalog)"
						>
							Edit
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="no-data"></div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../../components/partial/_Modal-pdf.vue'
	export default {
		data() {
			return {
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'x-large',
					store: {
						get: 'getCatalog',
						message: 'katalog',
						prefix: 'CATALOG',
						update: 'updateCatalog',
					},
					title: '$action katalog',
				},
				selected_data: null,
			}
		},
		components: {
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_catalog: 'CATALOG/catalog',
			}),
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_data = null
			},
			doEditCatalog(catalog) {
				this.selected_data = catalog
				this.is_modal_hidden = false
			},
			init() {
				this.$store.dispatch('CATALOG/getCatalog')
				this.is_loading = false
			},
		},
		mounted() {
			this.init()
		},
	}
</script>
<style lang="scss" scoped>
	@import '../../assets/scss/product/catalog.scss';
</style>
