<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_client"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<button class="btn-add" @click="doAddClient()">
			<i class="fa fa-plus"></i>Tambah
		</button>
		<div v-if="!isEmpty(state_clients)" class="item-list mt-16 our-client">
			<div
				v-bind:key="banner"
				v-for="banner in state_clients"
				class="item"
			>
				<img class="banner" :src="banner.image" />
				<div class="action-col">
					<button
						class="action"
						@click="doDeleteClient(banner.id)"
						v-if="deleting_list.indexOf(banner.id) < 0"
					>
						<i class="fa fa-trash"></i>
					</button>
					<button class="action" v-else>
						<i class="fas fa-circle-notch fa-spin"></i>
					</button>
					<button class="action" @click="doEditClient(banner)">
						<i class="fa fa-pencil-alt"></i>
					</button>
				</div>
			</div>
		</div>
		<div v-else class="no-data"></div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../../components/partial/_Modal-image.vue'
	export default {
		data() {
			return {
				deleting_list: [],
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'small',
					input_name: {
						title: 'Nama klien',
					},
					store: {
						add: 'addClient',
						get: 'getOurClients',
						message: 'klien',
						prefix: 'OUR_CLIENT',
						update: 'updateClient',
					},
					title: '$action klien',
				},
				selected_client: {
					id: null,
					image: null,
					name: null,
				},
			}
		},
		components: {
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_clients: 'OUR_CLIENT/our_clients',
			}),
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_client = {
					id: null,
					image: null,
					name: null,
				}
			},
			doAddClient() {
				this.is_modal_hidden = false
			},
			doDeleteClient(id) {
				this.deleting_list.push(id)
				this.$store
					.dispatch('OUR_CLIENT/deleteClient', id)
					.then(() => {
						this.$swal
							.fire({
								icon: 'success',
								title: 'Berhasil menghapus klien.',
								showConfirmButton: false,
								timer: 1500,
							})
							.then(() => {
								const index_item = this.deleting_list.indexOf(
									id,
								)
								delete this.deleting_list[index_item]
								this.init()
							})
					})
					.catch(() => {
						this.$swal.fire({
							icon: 'error',
							title:
								'Terjadi kesalahan pada sistem, harap coba lagi.',
							showConfirmButton: false,
							timer: 2000,
						})
					})
			},
			doEditClient(client) {
				this.selected_client = {
					id: client.id,
					image: client.image,
					name: client.name,
				}
				this.is_modal_hidden = false
			},
			init() {
				this.$store.dispatch('OUR_CLIENT/getOurClients')
				this.is_loading = false
			},
		},
		mounted() {
			this.init()
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/dashboard/our-client.scss';
</style>
