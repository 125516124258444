<template>
	<div v-if="state_footer">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_data"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<div class="mt-16">
			<my-table
				@init_data="init()"
				@edit="doEditCategory"
				:data="state_footer"
				:options="table_options"
				v-if="state_footer"
			></my-table>
		</div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'
import modal from '../components/partial/_Modal-text.vue'
import table from '../components/partial/_Table.vue'
export default {
	data() {
		return {
			is_modal_hidden: true,
			modal_options: {
				container_class: 'small',
				inputs: [
					{
						name: 'description',
						title: 'Deskripsi',
						type: 'text',
					},
					{
						name: 'phone',
						title: 'Nomor Telepon',
						type: 'text',
					},
					{
						name: 'email',
						title: 'Email',
						type: 'text',
					},
					{
						name: 'site',
						title: 'Situs Web',
						type: 'text',
					},
					{
						name: 'address',
						title: 'Alamat',
						type: 'textarea',
					},
				],
				store: {
					get: 'getFooters',
					message: 'footer',
					prefix: 'FOOTER',
					update: 'updateFooter',
				},
				title: '$action footer',
			},
			table_options: {
				columns: [
					{
						name: 'No',
						value: 'increment',
					},
					{
						name: 'Deskripsi',
						value: 'description',
					},
					{
						name: 'Telepon',
						value: 'phone',
					},
					{
						name: 'Email',
						value: 'email',
					},
					{
						name: 'Situs Web',
						value: 'site',
					},
					{
						name: 'Alamat',
						value: 'address',
					},
					{
						name: 'Aksi',
						value: 'action',
					},
				],
				can_delete: false,
				can_edit: true,
				size: 'large',
				store: {
					edit: 'updateFooter',
					message: 'kategori',
					prefix: 'FOOTER',
				},
			},
			selected_data: null,
		}
	},
	components: {
		'my-modal': modal,
		'my-table': table,
	},
	computed: {
		...mapGetters({
			state_footer: 'FOOTER/footers',
		}),
	},
	methods: {
		closeModal() {
			this.is_modal_hidden = true
			this.selected_data = null
		},
		doAddCategory() {
			this.is_modal_hidden = false
		},
		doEditCategory(category) {
			this.selected_data = category
			this.is_modal_hidden = false
		},
		init() {
			this.$store.dispatch('FOOTER/getFooters')
		},
	},
	mounted() {
		this.init()
	},
}
</script>