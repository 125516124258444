<template>
	<div v-if="!is_loading">
		<transition name="modal">
			<my-modal
				@close="closeModal()"
				v-if="!is_modal_hidden"
				:data="selected_banner"
				:options="modal_options"
			>
			</my-modal>
		</transition>
		<button class="btn-add" @click="doAddBanner()">
			<i class="fa fa-plus"></i>Tambah
		</button>
		<div v-if="!isEmpty(state_banners)" class="item-list mt-16">
			<div
				v-bind:key="banner"
				v-for="banner in state_banners"
				class="item"
			>
				<img class="banner" :src="banner.image" />
				<div class="action-col">
					<button
						class="action"
						@click="doDeleteBanner(banner.id)"
						v-if="deleting_list.indexOf(banner.id) < 0"
					>
						<i class="fa fa-trash"></i>
					</button>
					<button class="action" v-else>
						<i class="fas fa-circle-notch fa-spin"></i>
					</button>
					<button class="action" @click="doEditBanner(banner)">
						<i class="fa fa-pencil-alt"></i>
					</button>
				</div>
			</div>
		</div>
		<div v-else class="no-data"></div>
	</div>
	<div class="loading" v-else>
		<i class="fas fa-circle-notch fa-spin"></i>
	</div>
</template>
<script>
	import { mapGetters } from 'vuex'
	import modal from '../../components/partial/_Modal-image.vue'
	export default {
		data() {
			return {
				deleting_list: [],
				is_loading: true,
				is_modal_hidden: true,
				modal_options: {
					container_class: 'large',
					store: {
						add: 'addBanner',
						get: 'getBanners',
						message: 'baner',
						prefix: 'BANNER',
						update: 'updateBanner',
					},
					title: '$action baner',
				},
				selected_banner: {
					id: null,
					image: null,
					name: null,
				},
			}
		},
		components: {
			'my-modal': modal,
		},
		computed: {
			...mapGetters({
				state_banners: 'BANNER/banners',
			}),
		},
		methods: {
			closeModal() {
				this.is_modal_hidden = true
				this.selected_banner = {
					id: null,
					image: null,
					name: null,
				}
			},
			doAddBanner() {
				this.is_modal_hidden = false
			},
			doDeleteBanner(id) {
				this.deleting_list.push(id)
				this.$store
					.dispatch('BANNER/deleteBanner', id)
					.then(() => {
						this.$swal
							.fire({
								icon: 'success',
								title: 'Berhasil menghapus banner.',
								showConfirmButton: false,
								timer: 1500,
							})
							.then(() => {
								const index_item = this.deleting_list.indexOf(
									id,
								)
								delete this.deleting_list[index_item]
								this.init()
							})
					})
					.catch(() => {
						this.$swal.fire({
							icon: 'error',
							title:
								'Terjadi kesalahan pada sistem, harap coba lagi.',
							showConfirmButton: false,
							timer: 2000,
						})
					})
			},
			doEditBanner(banner) {
				this.selected_banner = {
					id: banner.id,
					image: banner.image,
					name: banner.name,
				}
				this.is_modal_hidden = false
			},
			init() {
				this.$store.dispatch('BANNER/getBanners')
				this.is_loading = false
			},
		},
		mounted() {
			this.init()
		},
	}
</script>
