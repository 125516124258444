import career_api from '../../../apis/career'

export default {
	async addCareer({ rootGetters }, update_data) {
		return await career_api
			.addCareer(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
	async deleteCareer({ rootGetters }, id) {
		await career_api.deleteCareer(id, rootGetters['AUTH/auth_token'])
	},
	async getCareers({ commit }) {
		let careers_ind = await career_api.getCareers('ind')
		let careers_eng = await career_api.getCareers('eng')

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		const temp = {}

		if (careers_ind.data.data.length > 0) {
			careers_ind.data.data = careers_ind.data.data
				.map(item => {
					const find_data_eng = careers_eng.data.data.find(
						item_eng => item_eng.id === item.id,
					)
					return {
						eng: find_data_eng,
						id: item.id,
						ind: item,
						image: `${api_uri}${item.image}`,
					}
				})
				.forEach(career => {
					temp[career.id] = career
				})
		}

		commit('setCareers', temp)
	},
	async updateCareer({ rootGetters }, update_data) {
		return await career_api
			.updateCareer(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
