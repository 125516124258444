import product_api from '../../../apis/product'

export default {
	async addProduct({ rootGetters }, update_data) {
		return await product_api
			.addProduct(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
	async deleteProduct({ rootGetters }, id) {
		await product_api.deleteProduct(id, rootGetters['AUTH/auth_token'])
	},
	async getProducts({ commit }) {
		const products_ind = await product_api.getProducts('ind')
		const products_eng = await product_api.getProducts('eng')

		const api_uri =
			process.env.APP_ENV === 'development'
				? process.env.VUE_APP_API_URL_DEV
				: process.env.VUE_APP_API_URL_PROD

		const temp = {}

		if (products_ind.data.data.length > 0) {
			products_ind.data.data = products_ind.data.data
				.map(item => {
					const find_eng = products_eng.data.data.find(
						item_eng => item_eng.id === item.id,
					)

					return {
						eng: find_eng,
						id: item.id,
						images: item.images.map(
							image => `${api_uri}${image.image}`,
						),
						ind: item,
						videos: item.videos,
					}
				})
				.forEach(product => {
					temp[product.id] = product
				})
		}

		commit('setProducts', temp)
	},
	async updateProduct({ rootGetters }, update_data) {
		return await product_api
			.updateProduct(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
