import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'
import routes from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'
import Cookies from 'js-cookie'

// styles

import 'sweetalert2/dist/sweetalert2.min.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './assets/scss/style.scss'

// mouting point for the whole app

import App from '@/App.vue'

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return {
			behavior: 'smooth',
			left: 0,
			top: 0,
		}
	},
})

router.beforeEach((to, _from, next) => {
	const token = Cookies.get('x-login')

	if (to.path === '/') {
		if (token) {
			next({
				name: 'banner',
			})
		} else {
			next({
				name: 'login',
			})
		}
	} else {
		if (to.meta.need_auth && !token) {
			Vue.$swal.fire({
				icon: 'warning',
				title: 'Sesi login Kamu telah habis, harap login lagi.',
				showConfirmButton: false,
				timer: 2000,
			})
			next({
				name: 'login',
			})
		} else {
			if (to.path === '/login' && token) {
				next({
					name: 'banner',
				})
			} else {
				next()
			}
		}
	}
})

// routes

const Vue = createApp(App)
	.use(router)
	.use(store)
	.use(VueSweetalert2)
	.mixin({
		methods: {
			isEmpty(value) {
				return (
					value == null ||
					value.length === 0 ||
					value === undefined ||
					value === {} ||
					value === [] ||
					value === ''
				)
			},
		},
	})
	.mount('#app')

export default Vue
