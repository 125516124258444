import our_team_api from '../../../apis/our-team'

export default {
	async getOurTeam({ commit }) {
		const our_team_ind = await our_team_api.getOurTeam('ind')
		const our_team_eng = await our_team_api.getOurTeam('eng')

		let our_team = null
		if (our_team_ind.data.data) {
			our_team = {
				eng: our_team_eng.data.data[0].description,
				id: our_team_ind.data.data[0].id,
				ind: our_team_ind.data.data[0].description,
			}
		}

		commit('setOurTeam', our_team)
	},
	async updateOurTeam({ rootGetters }, update_data) {
		return await our_team_api
			.updateOurTeam(update_data, rootGetters['AUTH/auth_token'])
			.then(resp => resp.data)
			.catch(err => err.response.data)
	},
}
