<template>
	<div class="modal-mask" v-on:click="$emit('close')">
		<div class="modal-wrapper">
			<div
				class="modal-container"
				:class="options.container_class"
				@click.stop=""
			>
				<div class="modal-body">
					<p class="title" v-if="options.title">
						{{ getTitle(options.title) }}
					</p>
					<div class="image-preview pdf">
						<object
							class="pdf-viewer"
							:data="getPdf"
							type="application/pdf"
							v-if="getPdf"
						>
							<embed :src="getPdf" type="application/pdf" />
						</object>
						<div class="placeholder" v-else>
							<i class="fa fa-file-pdf"></i>
						</div>
					</div>
					<div class="form-wrapper image">
						<div class="text-col">
							<p class="title">Unggah dokumen</p>
							<p class="description">Format dokumen .pdf</p>
						</div>
						<input
							accept=".pdf"
							class="hidden"
							ref="input_document"
							type="file"
							@change="handleFileInput"
						/>
						<button
							class="btn-upload"
							@click="$refs.input_document.click()"
						>
							<i class="fa fa-file-alt"></i>Pilih dokumen
						</button>
					</div>
				</div>
				<div class="modal-footer mt-8 mb-4">
					<button class="btn-cancel" v-on:click="$emit('close')">
						Batal
					</button>
					<button
						class="btn-save"
						:class="{ disabled: !can_save || is_uploading }"
						@click="handleSave"
					>
						Simpan
						<i
							class="fas fa-circle-notch fa-spin ml-1"
							v-if="is_uploading"
						></i>
					</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		emits: ['close'],
		props: ['data', 'options'],
		data() {
			return {
				can_save: false,
				pdf_temp: null,
				input_name: this.data ? this.data.name : '',
				is_uploading: false,
			}
		},
		computed: {
			getPdf() {
				return this.pdf_temp || this.data.files
			},
		},
		watch: {
			input_name: function(val) {
				if (val === '') {
					this.can_save = false
				}
			},
		},
		methods: {
			getTitle(title) {
				let action = 'Tambah'

				if (this.data.id) {
					action = 'Ubah'
				}

				return title.replace('$action', action)
			},
			handleFileInput(e) {
				if (e.target.files.length > 0) {
					this.pdf_temp = URL.createObjectURL(e.target.files[0])
					this.can_save = true
				} else {
					this.pdf_temp = null
					this.can_save = false
				}
			},
			handleSave() {
				if (this.can_save && !this.is_uploading) {
					const file_input = this.$refs.input_document
					if (this.getPdf) {
						this.is_uploading = true
						const form_data = new FormData()

						if (file_input.files[0]) {
							form_data.append('files', file_input.files[0])
						}

						let throw_data = form_data

						if (this.data.id) {
							throw_data = {
								id: this.data.id,
								form_data: form_data,
							}
						}

						this.$store
							.dispatch(
								`${this.options.store.prefix}/${
									this.data.id
										? this.options.store.update
										: this.options.store.add
								}`,
								throw_data,
							)
							.then(dt => {
								this.$store.dispatch(
									`${this.options.store.prefix}/${this.options.store.get}`,
								)
								this.$emit('close')
								this.$swal
									.fire({
										icon: dt.success ? 'success' : 'error',
										title: `${
											dt.success ? 'Berhasil' : 'Gagal'
										} ${
											this.data.id
												? 'merubah'
												: 'menambah'
										} ${this.options.store.message}.${
											dt.success ? '' : ` ${dt.msg}`
										}`,
										showConfirmButton: false,
										timer: dt.success ? 1500 : 3000,
									})
									.then(() => {
										this.is_uploading = false
									})
							})
							.catch(() => {
								this.is_uploading = false
								this.$swal.fire({
									icon: 'error',
									title:
										'Terjadi kesalahan pada sistem, harap coba lagi.',
									showConfirmButton: false,
									timer: 2000,
								})
							})
					} else if (!this.data) {
						this.$swal.fire({
							icon: 'error',
							title: 'Dokumen tidak boleh kosong.',
							showConfirmButton: false,
							timer: 2000,
						})
					}
				}
			},
		},
		beforeMount() {
			if (this.data) {
				this.can_save = true
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import '../../assets/scss/template/partial/_modal.scss';
</style>
